
import html2canvas from "html2canvas";
import { defineComponent, ref } from "vue";
import type { Interest } from "@mcss/dart/build/web";
import * as rover_interface from "../rover/interface";
import * as network from "../rover/network_protocol";
import { DartTopics } from "../dart_constants";

const ptzImageInterest = ref<Interest | null>(null);
export default defineComponent({
    name: "Ptz",
    props: {
        ptzType: {
            type: String,
            required: true,
        },
    },

    computed: {
        cameraUrl() {
            return this.$store.state.ptz.imageUrl;
        },
        distance_indicator() {
            const width = this.$store.state.ptz.dimensions.x;
            const height = this.$store.state.ptz.dimensions.y;
            const line_length = this.$store.state.ptz_info.scale_length * width;
            const offset = 20;

            const line_x1 = offset;
            const line_y1 = height - offset;
            const line_x2 = offset + line_length;
            const line_y2 = height - offset;

            return {
                label_x: 0.5 * (line_x1 + line_x2),
                label_y: line_y1 - 20,
                label_text: this.$store.state.ptz_info.scale_label,
                line_x1,
                line_y1,
                line_x2,
                line_y2,
                crosshair_centre: { x: width / 2, y: height / 2 },
                crosshair_size: 20,
                show: this.$store.state.ptz_info.in_zed_fov,
            };
        },
        aspectRatio() {
            switch (this.ptzType) {
                case "box":
                    return "none";
                case "full":
                    return "xMidYMid";
                default:
                    console.error("Unknown ptz type", this.ptzType);
                    return "none";
            }
        },
        svgViewbox() {
            return `0 0 ${this.$store.state.ptz.dimensions.x} ${this.$store.state.ptz.dimensions.y}`;
        },
        ptzStyle() {
            // Don't compute style if full mode
            if (this.ptzType === "full") return {};
            const aspect_ratio = this.$store.state.ptz.dimensions.x / this.$store.state.ptz.dimensions.y;
            const height = 300;
            return { width: (height * aspect_ratio).toString() + "px", height: height.toString() + "px" };
        },
    },
    async mounted() {
        await rover_interface.initialize();
        ptzImageInterest.value = rover_interface.collectPtzImage((imageUrl: string) => {
            this.$store.commit("updatePTZImage", imageUrl);

            // Get dimensions of PTZ image
            if (this.$store.state.ptz.dimensions.x === 0) {
                const img = new Image();
                img.src = imageUrl;
                img.onload = (): void => {
                    this.$store.commit("updatePTZImageSize", {
                        x: img.naturalWidth,
                        y: img.naturalHeight,
                    });
                };
                img.remove();
            }
        });
    },
    beforeUnmount() {
        rover_interface.uncollectPtz(ptzImageInterest.value);
        ptzImageInterest.value = null;
        this.$store.commit("updatePTZImage", null);
    },
    methods: {
        positionClick(e: MouseEvent) {
            // Move the PTZ on alt+click only
            if (!e.altKey) return;

            // Find the click offset as a proportion of the image width and height
            const videostream = document.getElementById("videostream");
            if (!(videostream instanceof HTMLImageElement)) {
                return;
            }
            const bounds = videostream.getBoundingClientRect();

            const clickX = e.clientX - bounds.left;
            const clickY = e.clientY - bounds.top;

            const relX = clickX / videostream.width;
            const relY = clickY / videostream.height;

            // Check if out of image bounds on PTZ Viewer screen
            if (relX < 0 || relX > 1.0) {
                return;
            }
            if (relY < 0 || relY > 1.0) {
                return;
            }

            // Find proportion of the FOV to move
            const moveX = relX - 0.5;
            const moveY = relY - 0.5;
            this.$store.commit("ptzMoveRelativeToFOV", { x: moveX, y: moveY });
        },
        async saveAs() {
            if (this.distance_indicator.show) {
                // Overlay the scale bars onto the image so as to not lose resolution
                rover_interface.setFreezeImageURLRevoke(true);
                try {
                    const svgWrapper = document.getElementById("distance-bars-wrapper");
                    if (svgWrapper === null) {
                        throw "Unable to find distance bars";
                    }

                    const canvas = await html2canvas(svgWrapper, {
                        scale: 1920 / svgWrapper.offsetWidth,
                        backgroundColor: null,
                    });

                    const blob = await new Promise((resolve: (value: Blob | null) => void) => canvas.toBlob(resolve));
                    if (blob === null) {
                        throw "Unable to create blob from canvas";
                    }

                    const scaleBarsUrl = URL.createObjectURL(blob);

                    // Store distance bars image
                    this.$store.commit("updateDistanceBars", scaleBarsUrl);

                    // Send request to save ptz image
                    this.storeHighestQualityPtzImg();
                } catch (error) {
                    console.error(error);
                    alert("Error downloading PTZ image...");
                } finally {
                    rover_interface.setFreezeImageURLRevoke(false);
                }
            } else {
                // Send request to save ptz image
                this.storeHighestQualityPtzImg();
            }
        },
        getPTZFeedBox() {
            const ptz_feed = document.getElementById("ptz-feed");
            if (!(ptz_feed instanceof HTMLElement)) {
                return;
            }
            return ptz_feed.getBoundingClientRect();
        },
        storeHighestQualityPtzImg() {
            this.$store.commit("ptzRequestImage", true);
            // Set ptz image compression to 0% to get best quality image.
            const ImgProperties = { compression: 0 };
            const topic = DartTopics.ptz_request_quality_image;
            rover_interface.sendData(topic, network.encodeJSON(ImgProperties));
        },
    },
});
