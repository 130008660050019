import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_toggles = _resolveComponent("toggles")!

  return (_openBlock(), _createBlock(_component_toggles, {
    "get-label": _ctx.getLabel,
    "get-available-topics": _ctx.getMaps,
    "get-topic-state": _ctx.getMapState,
    "toggle-topic": _ctx.toggleMap,
    title: `Overlays`
  }, null, 8, ["get-label", "get-available-topics", "get-topic-state", "toggle-topic"]))
}