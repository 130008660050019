import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "drive-joystick-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_JoystickComponent = _resolveComponent("JoystickComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_JoystickComponent, {
      class: _normalizeClass(_ctx.isAdmin() ? '' : 'half-jsc'),
      "jsc-type": _ctx.isAdmin() ? 'full' : 'half',
      "on-drag": _ctx.onDrag,
      "on-deactivate": _ctx.onDeactivate,
      "override-activate": _ctx.overrideActivate
    }, null, 8, ["class", "jsc-type", "on-drag", "on-deactivate", "override-activate"])
  ]))
}