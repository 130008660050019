/* CSV parsing helper methods */
export class CSVHelper {
    /**
     * Opens specified (CSV) file and returns data in array.
     * @param fileToRead Blob, the (CSV) file containing raw delimited data.
     * @returns Promise<Array<string>>, the parsed data in an array with row mapped to index..
     */
    static async getAsText(fileToRead: Blob): Promise<Array<string>> {
        return new Promise<Array<string>>((resolve) => {
            const reader = new FileReader();
            // Read file into memory as UTF-8
            reader.readAsText(fileToRead);
            // Once loaded, parse the CSV (async).
            reader.onload = (): void => {
                if (typeof reader.result !== "string") {
                    return;
                }
                const csv = reader.result;
                const result = this.processData(csv);
                resolve(result);
            };
            // Handle errors load
            reader.onerror = this.errorHandler;
        });
    }

    /**
     * Alerts in case of error while uploading ile.
     * @param event any, an error event.
     */
    static errorHandler(event: ProgressEvent<FileReader>): void {
        if (event.target === null || event.target.error === null) {
            alert("Invalid event error reading the file!");
            return;
        }
        if (event.target.error.name === "NotReadableError") {
            alert("There was an error reading the file!");
        }
    }

    /**
     * Checks if the specified point is on the real xy-plane.
     * @param dataPoint Array<string>, the point to check.
     * @returns boolean, true if valid, false otherwise.
     */
    static isCartestian(dataPoint: Array<string>): boolean {
        return (
            !(dataPoint[0] === "" || dataPoint[1] === "") &&
            !(isNaN(Number(dataPoint[0])) || isNaN(Number(dataPoint[1])))
        );
    }

    /**
     * Maps specified CSV to array. CSV rows are mapped to
     * array indices with row elements in parantheses
     * and seperated by commas (x, y).
     * @param csv string, the csv file to parse.
     * @returns Array<string>, the parsed data.[]
     */
    static processData(csv: string): Array<string> {
        const allTextLines = csv.split(/\r\n|\n/);
        const rows = allTextLines.reduce((rowArray: Array<string>, csvEntry: string) => {
            const data = csvEntry.split(";")[0].split(",");
            // Only real numbers.
            if (this.isCartestian(data)) {
                rowArray.push(`(${data[0]}, ${data[1]})`);
            }

            return rowArray;
        }, []);
        return rows;
    }
}

export default CSVHelper;
