
import { defineComponent } from "vue";
import * as rover_interface from "../rover/interface";
import { DartTopics } from "../dart_constants";

import driveState from "../rover/driveController";
import { roverAutonomous } from "../rover/index";
import { roverDisabled } from "../rover/interface";
import { clearPath } from "../rover/pathUpload";

import * as network from "../rover/network_protocol";

export default defineComponent({
    name: "DriveButtons",
    data() {
        return {
            driveState,
            roverAutonomous,
            roverDisabled,
        };
    },
    computed: {
        allowedToDrive() {
            return this.$store.state.allowedToDrive;
        },
    },
    methods: {
        toggleAutonomy() {
            // Update the store
            // Update the path follower state machine
            const topic = DartTopics.autonomy_state_topic;
            const drive_state = roverAutonomous() ? driveState.STOPPED : driveState.AUTOMATIC;
            const msg = { drive_state };
            rover_interface.sendData(topic, network.encodeJSON(msg));
        },
        toggleDisabled() {
            // Update the store
            // Update the path follower state machine
            const topic = DartTopics.autonomy_state_topic;
            const drive_state = roverDisabled() ? driveState.STOPPED : driveState.DISABLED;
            const msg = { drive_state };
            rover_interface.sendData(topic, network.encodeJSON(msg));
        },
        clearGoto() {
            clearPath();
        },
        killSwitchVisible() {
            return this.$store.state.allowedToDrive && this.$store.state.enableKillSwitch;
        },
        getBtnDisabled() {
            // Driving is not disabled if you are admin
            return roverDisabled() && !this.$store.state.isAdmin;
        },
    },
});
