import { Configuration } from "@ory/client";
import { V0alpha2Api } from "@ory/kratos-client";
import getConfig from "../config";

let kratos: V0alpha2Api;

async function getKratosAPI(): Promise<V0alpha2Api> {
    if (kratos === undefined) {
        const config = await getConfig();
        const kratosBaseUrl = config.VUE_APP_KRATOS_HOSTNAME;
        kratos = new V0alpha2Api(
            new Configuration({
                basePath: kratosBaseUrl,
                baseOptions: {
                    // Ensures we send cookies in the CORS requests.
                    withCredentials: true,
                },
            })
        );
    }
    return kratos;
}

export default getKratosAPI;
