
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        value: {
            type: Boolean,
            required: true,
        },
    },
    emits: ["input"],
    computed: {
        backgroundStyles() {
            return {
                "gold-mid": this.value,
                "gray-lighter": !this.value,
            };
        },
        indicatorStyles() {
            // Translation is 10px less than width
            return { transform: this.value ? "translateX(24px)" : "translateX(0)" };
        },
    },
    methods: {
        toggle() {
            this.$emit("input", !this.value);
        },
    },
});
