import { DartTopics } from "../dart_constants";

import * as network from "./network_protocol";
import * as rover_interface from "./interface";

export enum plannerType {
    DIRECT, // 0
    ASTAR, // 1
}

// This enum matches the one in opencv-filters/goto_overlay.h
export enum pathType {
    GOTO, // 0
    ANGLE, // 1
    SERIES, // 2
    MAP, // 3
    CLEAR, // 4 - Clears the current path
}

/**
 * Send coordinates as a path to follow to
 * path-coords-collector
 * @param input Object, the input message to send.
 * @param delay number, the rover delay.
 **/
export function sendPath(message: Record<string, unknown>): void {
    const emitTopic = DartTopics.path_upload_topic;
    rover_interface.sendData(emitTopic, network.encodeJSON(message));
}

// Clears goto path
export function clearPath(): void {
    const msg = { type: pathType.CLEAR };
    sendPath(msg);
}

/**
 * Create a float buffer from parsed CSV points.
 * @param pointArray Array<string>, array containing (x,y) point at each index.
 * @returns Float32Array, buffer containing the points: { p0_x, p0_y, ..., pn_x, pn_y }
 */
export function parsePoints(pointArray: Array<string>): Float32Array | null {
    // Return a typed array with points: each point is two 32-bit floats.
    const dv = new DataView(new ArrayBuffer(4 * (pointArray.length * 2)));
    const pointBuffer = new Float32Array(dv.buffer);

    // Don't send a buffer unless there's atleast 1 point.
    // pointArray is already filtered
    let validInput = false;

    pointArray.map((pointString: string, i) => {
        // Remove brackets (if there are any) and split into x [0], y [1].
        const point = pointString.replace(/[()]/g, "").split(",");
        // Make sure all parsed values are real numbers (already done but final incase.)
        const isFloat = !(isNaN(+point[0]) || isNaN(+point[1]));
        if (isFloat) {
            validInput = true;
            pointBuffer[i * 2] = parseFloat(point[0]);
            pointBuffer[i * 2 + 1] = parseFloat(point[1]);
        }
    });
    return validInput ? pointBuffer : null;
}
