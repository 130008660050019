import { HTML5GamepadService } from "@mcss/gamepad";
import { saveAs } from "file-saver";
import mergeImages from "merge-images";
// @ts-ignore lz4js doesn't have types
import { decompressBlock } from "lz4js";
import { getImageFilename } from "../img_utils";
import { DartTopics, Lz4ImageTopics, JpgImageTopics } from "../dart_constants";

import store, { RoverStatusData } from "../store";
import * as processGamepad from "./gamepad";
import { encodeJSON } from "./network_protocol";
import driveState from "./driveController";
import * as rover_interface from "./interface";
import { clearPath } from "./pathUpload";

const UPDATE_FREQUENCY = 40;

//In order to support high rate drive commands we allow the client to fall several acks behind
const MAX_DRIVER_ACKS_BEHIND = 4;

interface Rover {
    commanded_input: processGamepad.Velocity;
    gamepad_connected: boolean;
    moving: boolean;
}

interface DriveLineData {
    brakes: boolean; // True if engaged
    contactors: boolean; // True if on
}

interface WheelOdometryData {
    velocity: number;
    angular_rate: number;
}

interface VisualOdometryData {
    position: number[];
    velocity: number[];
    orientation: number[];
    angular_rate: number;
}

interface LocalPose {
    orientation: number[];
}

interface PtzCentrePose {
    position: number[];
}

const rover: Rover = {
    commanded_input: { linear: 0, angular: 0 },
    gamepad_connected: false,
    moving: false,
};

// Connect to the gamepad
const gamepad = new HTML5GamepadService();

// When a gamepad is plugged in and activated.
gamepad.on("connect", () => {
    console.log("gamepad connected");
    rover.gamepad_connected = true;
    store.commit("updateGamepad", rover.gamepad_connected);
});

// When gamepad is disconnected.
gamepad.on("disconnect", () => {
    console.log("gamepad disconnected");
    rover.gamepad_connected = false;
    store.commit("updateGamepad", rover.gamepad_connected);
});

export const roverAutonomous = (): boolean => {
    return store.state.rover.drive_state === driveState.AUTOMATIC;
};

// The state of the gamepad at 30hz.
gamepad.on("state", (state) => {
    // Define an object to store the input.
    let controllerInput;
    // Check Deadman and that the rover isn't in auto mode
    if (processGamepad.checkDeadman(state) && !roverAutonomous()) {
        controllerInput = processGamepad.thresholdCheck(state);
        // Enable DART emits.
        rover.moving = true;
    } else {
        // Clear the sticks
        controllerInput = processGamepad.resetSticks(state);
        // Disable DART emits.
        rover.moving = false;
    }

    // Trim the inputs to only focus on the buttons that
    // are used.
    rover.commanded_input = processGamepad.trimInput(controllerInput);

    // Set in the store
    store.commit("input", {
        moving: rover.moving,
        ...rover.commanded_input,
    });
});

// Map of topics that contain overlay images to label
export const overlay_labels: Record<string, string> = {
    [DartTopics.front_topic]: "RGB",
    [DartTopics.distance_topic]: "DISTANCE",
    [DartTopics.tracks_topic]: "TRACKS",
    [DartTopics.depth_topic]: "DEPTH",
    [DartTopics.hazard_topic]: "HAZARD",
    [DartTopics.elevation_topic]: "HEIGHT",
    [DartTopics.terrain_topic]: "TERRAIN",
    [DartTopics.path_topic]: "PATH",
    [DartTopics.grid_topic]: "GRID",
    [DartTopics.ptz_overlay_topic]: "PTZ",
};

// Map of topics that contain map data to label
export const map_labels: Record<string, string> = {
    [DartTopics.map_topic]: "MAP",
    [DartTopics.fov_map_topic]: "FOV",
    [DartTopics.terrain_map_topic]: "TERRAIN",
    [DartTopics.hazard_map_topic]: "HAZARD",
    [DartTopics.elevation_map_topic]: "ELEVATION",
    [DartTopics.grid_map_topic]: "GRID",
    [DartTopics.overlay_map_topic]: "PATH",
    [DartTopics.ptz_fov_map_topic]: "PTZ",
    [DartTopics.map_mark_topic]: "PTZ TAGS",
};

// Map topics that contain map images
export const map_image_topics: string[] = [
    DartTopics.map_topic,
    DartTopics.fov_map_topic,
    DartTopics.terrain_map_topic,
    DartTopics.hazard_map_topic,
    DartTopics.elevation_map_topic,
    DartTopics.grid_map_topic,
    DartTopics.overlay_map_topic,
    DartTopics.ptz_fov_map_topic,
];

// Map topics that contain vector data
export const map_vector_topics: string[] = [DartTopics.map_mark_topic];

/**
 * Connect to DART and setup the data feeds.
 * The URL is defined in the dart_constants file as VUE_APP_DART_URL.
 */
async function main(): Promise<void> {
    const TIMEOUT = 30000;
    let last_contact = 0;
    let connected = false;
    store.commit("setWindowSize", { x: window.innerWidth, y: window.innerHeight });

    window.addEventListener("resize", () => {
        store.commit("setWindowSize", { x: window.innerWidth, y: window.innerHeight });
    });

    store.commit("updateImageCallback", (imageUrl: string, topic: string) => {
        last_contact = new Date().getTime();

        // Update camera size if it is different
        if (topic === DartTopics.front_topic) {
            const img = new Image();
            img.src = imageUrl;
            img.onload = (): void => {
                if (
                    store.state.overlays.camera.size.width !== img.naturalWidth ||
                    store.state.overlays.camera.size.height !== img.naturalHeight
                ) {
                    store.commit("updateCameraSize", {
                        height: img.naturalHeight,
                        width: img.naturalWidth,
                    });
                }
                img.remove();
            };
        }

        store.commit("updateDataReceived", { topic, received: true });

        if (Lz4ImageTopics.includes(topic)) {
            const canvas = document.getElementById(overlay_labels[topic] + "-image") as HTMLCanvasElement;
            if (canvas) {
                const context = canvas.getContext("2d") as CanvasRenderingContext2D;

                fetch(imageUrl)
                    .then((request) => request.arrayBuffer())
                    .then((buffer) => {
                        const header = new Uint32Array(buffer, 0, 4);

                        const imageSize = header[0];
                        const imageWidth = header[1];
                        const imageHeight = header[2];
                        const compressedArray = new Uint8ClampedArray(buffer, 16);

                        const imageBuffer = new Uint8ClampedArray(imageSize);

                        // decompressBlock writes output to imageBuffer
                        decompressBlock(compressedArray, imageBuffer, 0, compressedArray.length, 0);

                        // We receive images in bgra colour order, but the canvas expects
                        // them in rgba, so swap the byte order.
                        for (let i = 0; i < imageBuffer.length; i += 4) {
                            const temp = imageBuffer[i];
                            imageBuffer[i] = imageBuffer[i + 2];
                            imageBuffer[i + 2] = temp;
                        }

                        const imageData = new ImageData(imageBuffer, imageWidth, imageHeight);

                        // Only resize canvas if dimensions changed.
                        // Resizing canvas unconditionally will result in flickering.
                        if (imageWidth !== canvas.width || imageHeight !== canvas.height) {
                            canvas.width = imageWidth;
                            canvas.height = imageHeight;
                        }

                        requestAnimationFrame(function () {
                            context.putImageData(imageData, 0, 0);
                        });
                    });
            }
        } else if (JpgImageTopics.includes(topic)) {
            store.commit("updateImage", { topic, imageUrl });
        }
    });

    Object.keys(overlay_labels).map((topic: string) => {
        if (topic === DartTopics.front_topic) {
            store.commit("updateShowImage", { topic, show: true });
            store.commit("updateShowImageButton", {
                topic,
                show_button: false,
            });
            store.commit("updateImageLabel", {
                topic,
                label: overlay_labels[topic],
            });
            store.commit("updateImageOpacity", { topic, opacity: 1 });
            store.commit("updateImageOrder", { topic, order: 0 });
        } else if (topic === DartTopics.depth_topic) {
            store.commit("updateShowImage", { topic, show: false });
            store.commit("updateShowImageButton", {
                topic,
                show_button: false,
            });
            store.commit("updateImageLabel", {
                topic,
                label: overlay_labels[topic],
            });
            store.commit("updateImageOpacity", { topic, opacity: 1 });
            store.commit("updateImageOrder", { topic, order: 1 });
        } else if (topic === DartTopics.hazard_topic) {
            store.commit("updateShowImage", { topic, show: false });
            store.commit("updateShowImageButton", {
                topic,
                show_button: false,
            });
            store.commit("updateImageLabel", {
                topic,
                label: overlay_labels[topic],
            });
            store.commit("updateImageOpacity", { topic, opacity: 1.0 });
            store.commit("updateImageOrder", { topic, order: 2 });
        } else {
            store.commit("updateShowImage", { topic, show: false });
            store.commit("updateShowImageButton", {
                topic,
                show_button: false,
            });
            store.commit("updateImageLabel", {
                topic,
                label: overlay_labels[topic],
            });
            store.commit("updateImageOpacity", { topic, opacity: 0.65 });
            store.commit("updateImageOrder", { topic, order: 2 });
        }

        store.commit("updateDataReceived", { topic, received: false });
    });

    await rover_interface.initialize();

    Object.keys(overlay_labels).map((topic: string) => {
        // Any image topic that needs a toggle button should be subscribed to initially
        // so that the toggle button can be made visible when an image arrives.
        if (topic !== DartTopics.front_topic) {
            const interest = rover_interface.collectImage(topic, () => {
                rover_interface.uncollect(interest);
                store.commit("updateShowImageButton", {
                    topic,
                    show_button: true,
                });
            });
        }
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rover_interface.collectJSON(DartTopics.zed_cam_settings_topic, (zed_cam_settings_json: any) => {
        store.commit("receiveZEDSettings", zed_cam_settings_json);
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rover_interface.collectJSON(DartTopics.led_headlight_topic, (led_headlight_state_json: any) => {
        store.commit("setLedHeadlight", led_headlight_state_json.new_state);
    });

    rover_interface.collectJSON(DartTopics.ptz_info_topic, (ptz_info_json) => {
        store.commit("updatePTZInfo", ptz_info_json);
    });

    rover_interface.collectJSON(DartTopics.ptz_centre_pose_topic, (pose) => {
        const ptz_centre_pose: PtzCentrePose = {
            position: pose.position,
        };
        store.commit("updatePTZCentrePose", ptz_centre_pose);
    });

    let last_new_path = -1;
    rover_interface.collectJSON(DartTopics.path_pixel_topic, (path_pixel_json) => {
        const { path, displacement, new_path, path_planner_type, error_message } = path_pixel_json;
        if (error_message !== undefined) {
            store.commit("updatePathOverlay", {
                type: "PATH_ERROR",
                payload: error_message,
            });
        }

        // Only update on new paths (this is toggled every goto click)
        // and if we're moving.
        if (new_path === last_new_path && store.state.rover.drive_state === driveState.STOPPED) {
            return;
        }
        last_new_path = new_path;

        store.commit("updatePathOverlay", {
            type: "PATH_PIXEL",
            payload: path ? path : null, // Store null if path is not defined.
        });
        store.commit("updatePathOverlay", {
            type: "PATH_DISPLACEMENT",
            payload: displacement,
        });
        store.commit("updatePathPlannerType", path_planner_type);
    });

    const distance_labels: string[] = [DartTopics.grid_labels_topic, DartTopics.distance_labels_topic];

    distance_labels.map((label_topic: string) => {
        rover_interface.collectJSON(label_topic, (label_json) => {
            store.commit("updateOverlayLabels", {
                type: label_topic, // The label topic
                payload: label_json, // Its json containing { [label] : position }
            });
        });
    });

    rover_interface.collectJSON(DartTopics.path_telemetry_topic, (path_telemetry) => {
        store.commit("updateDriveState", path_telemetry.drive_state);
    });

    rover_interface.collectJSON(DartTopics.rover_status_topic, (status: RoverStatusData) => {
        if (status !== null) {
            store.commit("updateRoverStatus", status);
        }
    });

    rover_interface.collectJSON(DartTopics.driveline_topic, (telem: DriveLineData) => {
        if (telem !== null) {
            store.commit("updateDrivelineData", telem);
        }
    });

    // Wheel odometry data
    rover_interface.collectJSON(DartTopics.wheel_odometry_topic, (pose) => {
        const wheel_odometry: WheelOdometryData = {
            velocity: pose.velocity[0],
            angular_rate: pose.angular_rate[2],
        };
        store.commit("updateWheelOdometryData", wheel_odometry);
        last_contact = new Date().getTime();
    });

    // Visual odometry data
    rover_interface.collectJSON(DartTopics.visual_odometry_topic, (pose) => {
        const visual_odometry: VisualOdometryData = {
            position: pose.position,
            velocity: pose.velocity,
            orientation: pose.orientation,
            angular_rate: pose.angular_rate[2],
        };
        store.commit("updateVisualOdometryData", visual_odometry);
        last_contact = new Date().getTime();
    });

    // Local pose data
    rover_interface.collectJSON(DartTopics.local_pose_topic, (pose) => {
        const local_pose: LocalPose = {
            orientation: pose.orientation,
        };
        store.commit("updateLocalPose", local_pose);
        last_contact = new Date().getTime();
    });

    [
        DartTopics.wheel_odometry_topic,
        DartTopics.slip_topic,
        DartTopics.visual_odometry_topic,
        DartTopics.imu_topic,
        DartTopics.map_coordinates_topic,
        DartTopics.gps_topic,
        DartTopics.rover_status_topic,
        DartTopics.svn_status_topic,
    ].map((topic: string) => {
        rover_interface.collectJSON(topic, (data) => {
            store.commit("updateDartViewer", { topic, data });
        });
    });

    rover_interface.collectJSON(DartTopics.slip_topic, (slip) => {
        store.commit("updateSlipData", slip.value);
    });

    rover_interface.collectRaw(DartTopics.lidar_topic, (lidar_points) => {
        store.commit("updateLidarPoints", lidar_points);
    });
    rover_interface.collectRaw(DartTopics.lidar_float_buf_topic, (lidar_points_float_buf) => {
        store.commit("updateLidarPointsFloatBuffer", lidar_points_float_buf);
    });
    rover_interface.collectRaw(DartTopics.lidar_short_buf_topic, (lidar_points_short_buf) => {
        store.commit("updateLidarPointsShortBuffer", lidar_points_short_buf);
    });

    rover_interface.collectJSON(DartTopics.objects_topic, (objects) => {
        store.commit("updateObjects", objects);
    });

    rover_interface.collectRaw(DartTopics.ptz_import_pic, (data) => {
        if (store.state.ptz.request_image === false) return;

        // Convert image data to a blob.
        const blob = new Blob([data], { type: "image/jpeg" });
        const imageURL = URL.createObjectURL(blob);

        // Get file name using x y coordinates
        const [x, y] = store.state.ptz_pose.centre_position_world;
        const filename = getImageFilename("ptz", x, y);

        // Get distance bars image URL
        const scaleBarsUrl = store.state.ptz.distanceBars;

        if (scaleBarsUrl !== null) {
            // Save merged image of distance and ptz image
            saveMergedImages(scaleBarsUrl, imageURL, blob, filename);
        } else {
            //just save the ptz image
            saveAs(imageURL, filename);
        }
        store.commit("ptzRequestImage", false);
    });

    async function saveMergedImages(
        scaleBarsUrl: string,
        imageURL: string,
        blob: Blob,
        filename: string
    ): Promise<void> {
        try {
            const b64 = await mergeImages([imageURL, scaleBarsUrl]);
            saveAs(b64, filename);
        } catch (error) {
            console.error(error);
            alert("Error Merging Image with Scale Bars...");
        }
    }

    // Collect initial data from maps so we can enable the toggles
    Object.keys(map_labels).map((mapTopic: string) => {
        // Initialize.
        const isEnabled = mapTopic === DartTopics.fov_map_topic; // Only FOV enabled by default
        store.commit("updateMapEnabled", { mapTopic, isEnabled });

        console.log("Collecting image " + mapTopic);
        const map_interest = rover_interface.collectRaw(mapTopic, () => {
            store.commit("updateMapButton", {
                mapTopic,
                button_visible: true,
            });
            rover_interface.uncollect(map_interest);
        });
    });

    // Set default options.
    store.commit("updateMapEnabled", {
        mapTopic: DartTopics.map_topic,
        isEnabled: true,
    });
    store.commit("updateMapOrder", {
        mapTopic: DartTopics.terrain_map_topic,
        order: 2,
    });
    store.commit("updateMapOrder", {
        mapTopic: DartTopics.elevation_map_topic,
        order: 2,
    });
    // Hazards are more important.
    store.commit("updateMapOrder", {
        mapTopic: DartTopics.hazard_map_topic,
        order: 3,
    });
    store.commit("updateMapOrder", {
        mapTopic: DartTopics.overlay_map_topic,
        order: 4,
    });
    store.commit("updateMapOrder", {
        mapTopic: DartTopics.map_mark_topic,
        order: 5,
    });

    store.commit("updateMapOpacity", {
        mapTopic: DartTopics.map_topic,
        opacity: 1.0,
    });
    store.commit("updateMapOpacity", {
        mapTopic: DartTopics.terrain_map_topic,
        opacity: 0.3,
    });
    store.commit("updateMapOpacity", {
        mapTopic: DartTopics.elevation_map_topic,
        opacity: 0.3,
    });
    store.commit("updateMapOpacity", {
        mapTopic: DartTopics.hazard_map_topic,
        opacity: 0,
    });
    store.commit("updateMapOpacity", {
        mapTopic: DartTopics.overlay_map_topic,
        opacity: 0,
    });
    store.commit("updateMapOpacity", {
        mapTopic: DartTopics.map_mark_topic,
        opacity: 0.8,
    });

    store.commit("updateMapButton", {
        mapTopic: DartTopics.map_topic,
        button_visible: false,
    });
    store.commit("updateMapButton", {
        mapTopic: DartTopics.terrain_map_topic,
        button_visible: false,
    });
    store.commit("updateMapButton", {
        mapTopic: DartTopics.elevation_map_topic,
        button_visible: false,
    });
    store.commit("updateMapButton", {
        mapTopic: DartTopics.hazard_map_topic,
        button_visible: false,
    });

    store.commit("updateMapButton", {
        mapTopic: DartTopics.overlay_map_topic,
        button_visible: false,
    });

    // Hazards are more important.
    store.commit("updateMapOrder", {
        mapTopic: DartTopics.hazard_map_topic,
        order: 3,
    });
    store.commit("updateMapOpacity", {
        mapTopic: DartTopics.map_topic,
        opacity: 1.0,
    });
    store.commit("updateMapOpacity", {
        mapTopic: DartTopics.terrain_map_topic,
        opacity: 0.3,
    });
    store.commit("updateMapOpacity", {
        mapTopic: DartTopics.elevation_map_topic,
        opacity: 0.3,
    });
    store.commit("updateMapOpacity", {
        mapTopic: DartTopics.hazard_map_topic,
        opacity: 0,
    });

    store.commit("updateMapButton", {
        mapTopic: DartTopics.map_topic,
        visible: false,
    });
    store.commit("updateMapButton", {
        mapTopic: DartTopics.terrain_map_topic,
        visible: false,
    });
    store.commit("updateMapButton", {
        mapTopic: DartTopics.elevation_map_topic,
        visible: false,
    });
    store.commit("updateMapButton", {
        mapTopic: DartTopics.hazard_map_topic,
        visible: false,
    });

    store.commit("updateMapButton", {
        mapTopic: DartTopics.overlay_map_topic,
        visible: false,
    });

    // Update at the specified frequency.
    setInterval(
        () => {
            // Check if the rover is connected.
            const is_connected = new Date().getTime() - last_contact <= TIMEOUT;
            // Check if the connection status has changed.
            if (connected !== is_connected) {
                // Update the local state.
                connected = is_connected;
                // Update the store.
                store.commit("updateRover", connected);
            }
            // Skip the loop if the user is not authenticated.
            if (store.state.authenticated === false) return;

            if (store.state.ptz.new_data || store.state.ptz.is_dragging) {
                rover_interface.sendData(DartTopics.ptz_control_topic, encodeJSON(store.state.ptz), {
                    delay: store.state.rover.command_delay,
                    max_acks_waiting: MAX_DRIVER_ACKS_BEHIND,
                });
                store.commit("ptzMoveAbsolute", null);
                store.commit("ptzMoveToZedPixel", null);
                store.commit("ptzMoveRelativeToFOV", null);
                store.commit("ptzRestart", false);
                store.commit("ptzClearNewFlag");
            }

            if (store.state.requests.length > 0) {
                for (const request of store.state.requests) {
                    // send reliably
                    rover_interface.sendData(request, encodeJSON({ timestamp: Date.now() }), {
                        delay: store.state.rover.command_delay,
                    });
                }
                store.commit("clearRequests");
            }

            // Send driving commands if they are non-zero.
            rover.commanded_input = {
                linear: store.state.rover.linear,
                angular: store.state.rover.angular,
            };
            if (store.state.rover.moving === true && store.state.allowedToDrive === true) {
                rover_interface.sendData(DartTopics.driving_command_topic, encodeJSON(rover.commanded_input), {
                    delay: store.state.rover.command_delay,
                    max_acks_waiting: MAX_DRIVER_ACKS_BEHIND,
                });

                // Clear goto on driving command
                clearPath();
            }
        },
        // Calculate the update period from the frequency.
        1000 / UPDATE_FREQUENCY
    );
}

main();

export default rover;
