import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-30a197ac"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "drive-buttons" }
const _hoisted_2 = { class: "button-container" }
const _hoisted_3 = ["disabled"]
const _hoisted_4 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("button", {
        id: "drive-disable-btn",
        style: _normalizeStyle({
                    display: _ctx.killSwitchVisible() ? 'block' : 'none',
                }),
        class: _normalizeClass(_ctx.roverDisabled() ? 'accept-btn' : 'danger-btn'),
        type: "button",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleDisabled && _ctx.toggleDisabled(...args)))
      }, _toDisplayString(_ctx.roverDisabled() ? "ENABLE" : "DISABLE"), 7),
      _createElementVNode("button", {
        id: "autonomy-btn",
        style: _normalizeStyle({
                    visibility: _ctx.allowedToDrive ? 'visible' : 'hidden',
                }),
        disabled: _ctx.getBtnDisabled(),
        class: _normalizeClass(_ctx.roverAutonomous() ? 'danger-btn' : 'accept-btn'),
        type: "button",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.toggleAutonomy && _ctx.toggleAutonomy(...args)))
      }, _toDisplayString(_ctx.roverAutonomous() ? "STOP" : "FOLLOW"), 15, _hoisted_3),
      _createElementVNode("button", {
        id: "clear-btn",
        style: _normalizeStyle({
                    display: _ctx.roverAutonomous() ? 'none' : 'inline',
                }),
        disabled: _ctx.getBtnDisabled(),
        class: "accept-btn",
        type: "button",
        onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.clearGoto && _ctx.clearGoto(...args)))
      }, _toDisplayString(_ctx.roverAutonomous() ? "CLEAR" : "CLEAR"), 13, _hoisted_4)
    ])
  ]))
}