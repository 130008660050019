import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "./assets/styles/theme.scss";
import "./assets/fa/css/fontawesome-all.css";

function buildTimestamp(): string {
    if (process.env.VUE_APP_BUILD_UNIX_TIME !== undefined) {
        const unixTimestamp = Number(process.env.VUE_APP_BUILD_UNIX_TIME);
        const milliseconds = unixTimestamp * 1000;
        const buildDate = new Date(milliseconds);
        return buildDate.toDateString() + "\n\t" + buildDate.toTimeString();
    } else {
        return "Not set";
    }
}

const app = createApp(App);

app.use(router);
app.use(store);

app.mount("#app");

console.log("Build timestamp: " + buildTimestamp());
