import { createStore } from "vuex";
import { DartTopics } from "./dart_constants";
import { PtzInterfacePitchYaw, PtzMoveAbsolute, PtzInfoInterface, PtzCentrePose } from "./ptz";
import driveState from "./rover/driveController";
import type { Vector2, Quaternion } from "./types";
import quaternionToYpr from "./rover/quaternion";

// This enum matches the one in path-planner-lib typedefs.h
enum PathPlannerErrorType {
    NO_ERROR, // 0
    OCCUPIED_START, // 1
    OCCUPIED_GOAL, // 2
    OFF_MAP_START, // 3
    OFF_MAP_GOAL, // 4
    UNKNOWN_POSE, // 5
    UNKNOWN_ERROR, // 6
}

interface MapInfo {
    mapTopic: string;
    isEnabled?: boolean;
    order?: number;
    opacity?: number;
    button_visible?: boolean;
    imageUrl?: string;
    data?: any;
    interest?: any;
}

interface ImageInfo {
    topic: string;
    imageUrl?: string;
    show?: boolean;
    show_button?: boolean;
    label?: string;
    interest?: any;
    opacity?: number;
    order?: number;
    received?: boolean;
}

interface CameraSize {
    height: number | null;
    width: number | null;
}

interface OverlayInfo {
    type: string;
    payload?: unknown;
}

interface ZedSettings {
    gain: number;
    exposure: number;
    saturation: number;
    autogain: boolean;
    autoexposure: boolean;
    autosaturation: boolean;
}

interface RoverMovement {
    linear: number;
    angular: number;
    moving: boolean;
}

interface UserInterface {
    email: string;
    first_name: string;
    last_name: string;
    full_name: string;
    token: string;
}

interface DriveLineData {
    brakes: boolean; // True if engaged
    contactors: boolean; // True if on
}

interface WheelOdometryData {
    velocity: number;
    angular_rate: number;
}

interface VisualOdometryData {
    position: number[];
    velocity: number[];
    orientation: number[];
    angular_rate: number;
}

interface LocalPose {
    orientation: Quaternion;
}

interface GotoOverlayCoord {
    x: number;
    y: number;
    width: number;
}

type WarningType = "danger" | "caution" | "";

export interface RoverTrackSample {
    outer: Vector2;
    inner: Vector2;
}

export interface LabelsAndCoordinates {
    [key: string]: Vector2;
}

export interface RoverStatusData {
    charge_estimate: {
        value: number;
    };
    battery_voltage: {
        value: number;
    };
    e_stop: {
        value: boolean;
    }; // True if engaged
}

export interface State {
    authenticated: boolean;
    window_size: { x: number; y: number };
    user: {
        email: string;
        first_name: string;
        last_name: string;
        full_name: string;
    };
    rover: {
        connected: boolean;
        drive_state: driveState;
        brakes: boolean;
        contactors: boolean;
        visual_odometry_position: number[];
        visual_odometry_velocity: number[];
        visual_odometry_angular_rate: number;
        pitch_alert: WarningType;
        roll_alert: WarningType;
        local_pose_orientation: Quaternion; // quaternion: [x, y, z, w]
        wheel_odometry_velocity: number; // The velocity of the rover based on actual wheel speed and no slip
        wheel_odometry_yaw_rate: number; // The yaw rate of the rover based on actual wheel speed and no slip
        linear: number;
        angular: number;
        moving: boolean;
        gamepad: boolean;
        slip: number;
        objects: never[];
        points: Uint8Array;
        // TODO: Double check these types. Is points_float_buf really a Uint8Array?
        points_float_buf: Uint8Array;
        points_short_buf: Uint8Array;
        command_delay: number;
        telemetry_warning: WarningType;
        battery_charge: number;
        battery_charge_warning: WarningType;
        battery_voltage: number;
        battery_charge_voltage: WarningType;
        path_planner: number; //0 => direct (default), 1 => astar
        e_stop: boolean;
        e_stop_warning: WarningType;
    };
    zed_cam: {
        gain: number;
        exposure: number;
        saturation: number;
        autogain: boolean;
        autoexposure: boolean;
        autosaturation: boolean;
    };
    relays: {
        led_headlight: boolean;
    };
    ptz: {
        pitch_rate: number;
        yaw_rate: number;
        dimensions: { x: number; y: number };
        move_absolute: null | { yaw: number; pitch: number }; // no command if null, else {yaw, pitch} to move to
        move_to_zed_pixel: null | { x: number; y: number }; // no command if null, else {x: x, y: y} of zed-camera pixel to move to
        move_relative_to_fov: null | { x: number; y: number }; // same, but x/y is a proportion of the PTZ FOV to move
        zoom: number;
        focus: number;
        exposure: number;
        autofocus: boolean;
        autoexposure: boolean;
        restart: boolean;
        imageUrl: string | null; // TODO: some default image?
        distanceBars: string | null;
        feedEnabled: boolean;
        new_data: boolean;
        is_dragging: boolean;
        enable_restart_button: boolean;
        request_image: boolean;
    };
    ptz_info: {
        scale_label: string;
        scale_length: number;
        in_zed_fov: boolean;
    };
    ptz_pose: {
        // Position of centre of PTZ's FOV relative to world, or the PTZ's position if ptz_info.in_zed_fov is boolean
        centre_position_world: number[];
    };
    overlays: {
        camera: {
            size: {
                height: number | null;
                width: number | null;
            };
        };
        path: {
            path_pixel_coords: GotoOverlayCoord[];
            path_displacement: number | null;
            error_message: string | null;
            error_message_timeout_id: number | null;
        };
        tracks: {
            left: RoverTrackSample[];
            right: RoverTrackSample[];
        };
        distance: {
            labels: LabelsAndCoordinates;
        };
        grid: {
            labels: LabelsAndCoordinates;
        };
    };
    images: {
        [key: string]: any;
    };
    image_callback: () => void;
    requests: string[];
    dartStatus: boolean;
    dartViewer: {};
    maps: { [key: string]: any };
    map_marks: {}[];
    allowedToDrive: boolean;
    enableSidebar: boolean;
    enableKillSwitch: boolean;
    isAdmin: boolean;
}

export default createStore<State>({
    state: {
        authenticated: false,
        window_size: { x: 1280, y: 720 },
        user: {
            email: "",
            first_name: "",
            last_name: "",
            full_name: "",
        },
        rover: {
            connected: false,
            drive_state: driveState.STOPPED,
            brakes: false,
            contactors: false,
            visual_odometry_position: [0, 0, 0],
            visual_odometry_velocity: [0, 0, 0],
            visual_odometry_angular_rate: 0,
            pitch_alert: "",
            roll_alert: "",
            local_pose_orientation: [0, 0, 0, 0], // quaternion: [x, y, z, w]
            wheel_odometry_velocity: 0, // The velocity of the rover based on actual wheel speed and no slip
            wheel_odometry_yaw_rate: 0, // The yaw rate of the rover based on actual wheel speed and no slip
            linear: 0,
            angular: 0,
            moving: false,
            gamepad: false,
            slip: 0.0,
            objects: [],
            points: new Uint8Array(),
            points_float_buf: new Uint8Array(),
            points_short_buf: new Uint8Array(),
            command_delay: 0,
            telemetry_warning: "",
            battery_charge: 0,
            battery_charge_warning: "",
            battery_voltage: 0,
            battery_charge_voltage: "",
            path_planner: 0, //0 => direct (default), 1 => astar
            e_stop: false,
            e_stop_warning: "",
        },
        zed_cam: {
            gain: -1,
            exposure: -1,
            saturation: -1,
            autogain: true,
            autoexposure: true,
            autosaturation: true,
        },
        relays: {
            led_headlight: false,
        },
        ptz: {
            pitch_rate: 0,
            yaw_rate: 0,
            dimensions: { x: 0, y: 0 },
            move_absolute: null, // no command if null, else {yaw, pitch} to move to
            move_to_zed_pixel: null, // no command if null, else {x: x, y: y} of zed-camera pixel to move to
            move_relative_to_fov: null, // same, but x/y is a proportion of the PTZ FOV to move
            zoom: 0,
            focus: 50,
            exposure: 50,
            autofocus: true,
            autoexposure: true,
            restart: false,
            imageUrl: null, // TODO: some default image?
            distanceBars: null,
            feedEnabled: false,
            new_data: false,
            is_dragging: false,
            enable_restart_button: false,
            request_image: false,
        },
        ptz_info: {
            scale_label: "",
            scale_length: 0,
            in_zed_fov: false,
        },
        ptz_pose: {
            // Position of centre of PTZ's FOV relative to world, or the PTZ's position if ptz_info.in_zed_fov is false
            centre_position_world: [0, 0, 0],
        },
        overlays: {
            camera: {
                size: {
                    height: null,
                    width: null,
                },
            },
            path: {
                path_pixel_coords: [],
                path_displacement: null,
                error_message: null,
                error_message_timeout_id: null,
            },
            tracks: {
                left: [],
                right: [],
            },
            distance: {
                labels: { "1m": { x: 0, y: 0 } },
            },
            grid: {
                labels: { "x=1m": { x: 0, y: 0 } },
            },
        },
        images: { [DartTopics.front_topic]: { imageUrl: "" } },
        image_callback: () => {},
        requests: [],
        dartStatus: false,
        dartViewer: {},
        maps: {},
        map_marks: [],
        allowedToDrive: true,
        enableSidebar: true,
        enableKillSwitch: false,
        isAdmin: false,
    },
    mutations: {
        updateImage(state: State, imageInfo: ImageInfo) {
            const { topic, imageUrl } = imageInfo;
            state.images = {
                ...state.images,
                [topic]: {
                    ...state.images[topic],
                    imageUrl,
                },
            };
        },
        updateShowImage(state: State, imageInfo: ImageInfo) {
            const { topic, show } = imageInfo;
            state.images = {
                ...state.images,
                [topic]: {
                    ...state.images[topic],
                    show,
                },
            };
        },
        updateShowImageButton(state: State, imageInfo: ImageInfo) {
            const { topic, show_button } = imageInfo;
            state.images = {
                ...state.images,
                [topic]: {
                    ...state.images[topic],
                    show_button,
                },
            };
        },
        updateImageLabel(state: State, imageInfo: ImageInfo) {
            const { topic, label } = imageInfo;
            state.images = {
                ...state.images,
                [topic]: {
                    ...state.images[topic],
                    label,
                },
            };
        },
        updateImageInterest(state: State, imageInfo: ImageInfo) {
            const { topic, interest } = imageInfo;
            state.images = {
                ...state.images,
                [topic]: {
                    ...state.images[topic],
                    interest,
                },
            };
        },
        updateImageOpacity(state: State, imageInfo: ImageInfo) {
            const { topic, opacity } = imageInfo;
            state.images = {
                ...state.images,
                [topic]: {
                    ...state.images[topic],
                    opacity,
                },
            };
        },
        updateImageOrder(state: State, imageInfo: ImageInfo) {
            const { topic, order } = imageInfo;
            state.images = {
                ...state.images,
                [topic]: {
                    ...state.images[topic],
                    order,
                },
            };
        },
        updateDataReceived(state: State, imageInfo: ImageInfo) {
            const { topic, received } = imageInfo;
            state.images = {
                ...state.images,
                [topic]: {
                    ...state.images[topic],
                    received,
                },
            };
        },
        updateImageCallback(state: State, callback: any) {
            state.image_callback = callback;
        },
        updateCameraSize(state: State, newSize: CameraSize) {
            state.overlays.camera.size = newSize;
        },
        updatePathOverlay(state: State, action: OverlayInfo) {
            const { type, payload } = action;

            switch (type) {
                case "PATH_PIXEL": // payload is GotoOverlayCoord[]
                    state.overlays.path.path_pixel_coords = payload as GotoOverlayCoord[];
                    break;

                case "PATH_DISPLACEMENT": // payload a number or null
                    state.overlays.path.path_displacement = payload as number | null;
                    break;

                case "PATH_ERROR": {
                    let path_planner_err_msg = "Path Planner Failed: ";
                    // payload is a number
                    switch (payload as number) {
                        case PathPlannerErrorType.NO_ERROR:
                            path_planner_err_msg = "";
                            break;
                        case PathPlannerErrorType.OCCUPIED_START:
                            path_planner_err_msg += "Start position is occupied";
                            break;
                        case PathPlannerErrorType.OCCUPIED_GOAL:
                            path_planner_err_msg += "Goal position is occupied";
                            break;
                        case PathPlannerErrorType.OFF_MAP_START:
                            path_planner_err_msg += "Start position is off the map";
                            break;
                        case PathPlannerErrorType.OFF_MAP_GOAL:
                            path_planner_err_msg += "Goal position is off the map";
                            break;
                        default:
                            path_planner_err_msg += "Unknown path planner error";
                            break;
                    }
                    state.overlays.path.error_message = path_planner_err_msg;
                    // Clear previous timeout
                    if (state.overlays.path.error_message_timeout_id !== null) {
                        clearTimeout(state.overlays.path.error_message_timeout_id);
                    }
                    // Set new timeout to clear error message
                    state.overlays.path.error_message_timeout_id = window.setTimeout(function () {
                        state.overlays.path.error_message = null;
                    }, 5000);
                    break;
                }

                default:
                    break;
            }
        },
        updatePathPlannerType(state: State, path_planner_type: number) {
            state.rover.path_planner = path_planner_type;
        },
        updateOverlayLabels(state: State, action: OverlayInfo) {
            const { type, payload } = action;

            switch (type) {
                case DartTopics.distance_labels_topic:
                    state.overlays.distance.labels = payload as LabelsAndCoordinates;
                    break;

                case DartTopics.grid_labels_topic:
                    state.overlays.grid.labels = payload as LabelsAndCoordinates;
                    break;

                default:
                    break;
            }
        },
        updateTracksOverlay(state: State, new_json: { left: RoverTrackSample[]; right: RoverTrackSample[] }) {
            state.overlays.tracks = new_json;
        },
        updateDriveState(state: State, driving_state: driveState) {
            state.rover.drive_state = driving_state;
        },
        updateObjects(state: State, objects: string) {
            state.rover.objects = JSON.parse(objects);
        },
        updateLidarPoints(state: State, points: Uint8Array) {
            state.rover.points = points;
        },
        updateLidarPointsFloatBuffer(state: State, points_float_buf: Uint8Array) {
            state.rover.points_float_buf = points_float_buf;
        },
        updateLidarPointsShortBuffer(state: State, points_short_buf: Uint8Array) {
            state.rover.points_short_buf = points_short_buf;
        },
        updateGamepad(state: State, gamepad_connected: boolean) {
            state.rover.gamepad = gamepad_connected;
        },
        updateRover(state: State, rover_connected: boolean) {
            state.rover.connected = rover_connected;
        },
        input(state: State, ci: RoverMovement) {
            state.rover.linear = ci.linear;
            state.rover.angular = ci.angular;
            state.rover.moving = ci.moving;
        },
        receiveZEDSettings(state: State, settings: ZedSettings) {
            state.zed_cam.gain = settings.gain;
            state.zed_cam.autogain = settings.gain === -1 ? true : false;
            state.zed_cam.exposure = settings.exposure;
            state.zed_cam.autoexposure = settings.exposure === -1 ? true : false;
            state.zed_cam.saturation = settings.saturation;
            state.zed_cam.autosaturation = settings.saturation === -1 ? true : false;
        },
        setLedHeadlight(state: State, relay_state: boolean) {
            state.relays.led_headlight = relay_state;
        },
        ptzPan(state: State, rates: PtzInterfacePitchYaw) {
            state.ptz.pitch_rate = rates.pitch_rate;
            state.ptz.yaw_rate = rates.yaw_rate;
            state.ptz.new_data = true;
            state.ptz.is_dragging = true;
        },
        ptzStopPan(state: State, rates: PtzInterfacePitchYaw) {
            state.ptz.pitch_rate = rates.pitch_rate;
            state.ptz.yaw_rate = rates.yaw_rate;
            state.ptz.new_data = true;
            state.ptz.is_dragging = false;
        },
        ptzZoom(state: State, zoom: number) {
            state.ptz.zoom = zoom;
            state.ptz.new_data = true;
        },
        ptzFocus(state: State, focus: number) {
            state.ptz.focus = focus;
            state.ptz.new_data = true;
        },
        ptzExposure(state: State, exposure: number) {
            state.ptz.exposure = exposure;
            state.ptz.new_data = true;
        },
        ptzAutofocus(state: State, autofocus: boolean) {
            state.ptz.autofocus = autofocus;
            state.ptz.new_data = true;
        },
        ptzAutoexposure(state: State, autoexposure: boolean) {
            state.ptz.autoexposure = autoexposure;
            state.ptz.new_data = true;
        },
        ptzMoveAbsolute(state: State, pos: PtzMoveAbsolute) {
            state.ptz.move_absolute = pos;
            state.ptz.new_data = true;
        },
        ptzMoveToZedPixel(state: State, pixel: Vector2) {
            state.ptz.move_to_zed_pixel = pixel;
            state.ptz.new_data = true;
        },
        ptzMoveRelativeToFOV(state: State, pixel: { x: number; y: number }) {
            state.ptz.move_relative_to_fov = pixel;
            state.ptz.new_data = true;
        },
        ptzRestart(state: State, restart: boolean) {
            state.ptz.restart = restart;
            state.ptz.new_data = true;
        },
        ptzRequestImage(state: State, request_image: boolean) {
            state.ptz.request_image = request_image;
        },
        ptzClearNewFlag(state: State) {
            state.ptz.new_data = false;
        },
        updatePTZEnableRestartButton(state: State, enable_restart_button: boolean) {
            state.ptz.enable_restart_button = enable_restart_button;
        },
        updatePTZFeedEnable(state: State, isEnabled: boolean) {
            state.ptz.feedEnabled = isEnabled;
        },
        updatePTZImage(state: State, imageUrl: string) {
            state.ptz.imageUrl = imageUrl;
        },
        updatePTZImageSize(state: State, dims: Vector2) {
            state.ptz.dimensions = dims;
        },
        updateDistanceBars(state: State, distanceBarsUrl: string) {
            state.ptz.distanceBars = distanceBarsUrl;
        },
        updatePTZInfo(state: State, info: PtzInfoInterface) {
            state.ptz_info = info;
            if (info.new_data === true) {
                state.ptz.autoexposure = info.autoexposure;
                state.ptz.autofocus = info.autofocus;
                state.ptz.exposure = info.unaltered_exposure;
                state.ptz.focus = info.unaltered_focus;
                state.ptz.zoom = info.unaltered_zoom;
            }
        },
        updatePTZCentrePose(state: State, pose: PtzCentrePose) {
            state.ptz_pose.centre_position_world = pose.position;
        },
        login(state: State, user: UserInterface) {
            state.authenticated = true;
            state.user.email = user.email;
            state.user.first_name = user.first_name;
            state.user.last_name = user.last_name;

            // Only sets the name if first and/or last name
            // are given.
            let full_name = "";

            if (user.first_name !== undefined) {
                full_name = full_name + user.first_name;
            }
            if (user.last_name !== undefined) {
                full_name = full_name + " " + user.last_name;
            }
            state.user.full_name = full_name;
        },
        logout(state: State) {
            state.authenticated = false;
            state.user.email = "";
            state.user.first_name = "";
            state.user.last_name = "";
            state.user.full_name = "";
        },
        updateDrivelineData(state: State, driveline_data: DriveLineData) {
            state.rover.brakes = driveline_data.brakes !== false;
            state.rover.contactors = driveline_data.contactors !== false;
        },
        updateWheelOdometryData(state: State, wheel_odometry_data: WheelOdometryData) {
            state.rover.wheel_odometry_velocity = wheel_odometry_data.velocity;
            state.rover.wheel_odometry_yaw_rate = wheel_odometry_data.angular_rate;
        },
        updateVisualOdometryData(state: State, visual_odometry_data: VisualOdometryData) {
            state.rover.visual_odometry_position = visual_odometry_data.position;
            state.rover.visual_odometry_velocity = visual_odometry_data.velocity;
            state.rover.visual_odometry_angular_rate = visual_odometry_data.angular_rate;
        },
        updateLocalPose(state: State, local_pose: LocalPose) {
            const [roll, pitch] = quaternionToYpr(local_pose.orientation);
            const pitchInDeg = (180 * pitch) / Math.PI;
            const rollInDeg = (180 * roll) / Math.PI;

            state.rover.local_pose_orientation = local_pose.orientation;
            state.rover.pitch_alert = Math.abs(pitchInDeg) > 24 ? "danger" : Math.abs(pitchInDeg) > 12 ? "caution" : "";
            state.rover.roll_alert = Math.abs(rollInDeg) > 16 ? "danger" : Math.abs(rollInDeg) > 8 ? "caution" : "";

            checkTelemetryWarnings(state);
        },
        updateSlipData(state: State, slip: number) {
            state.rover.slip = slip;
        },
        updateDartStatus(state: State, status: boolean) {
            state.dartStatus = status;
        },
        updateDartViewer(state: State, data: any) {
            state.dartViewer = { ...state.dartViewer, [data.topic]: data.data };
        },
        updateMapEnabled(state: State, mapInfo: MapInfo) {
            const { mapTopic, isEnabled } = mapInfo;

            state.maps = {
                ...state.maps,
                [mapTopic]: {
                    ...state.maps[mapTopic],
                    isEnabled,
                },
            };
        },
        updateMapOrder(state: State, mapInfo: MapInfo) {
            const { mapTopic, order } = mapInfo;

            state.maps = {
                ...state.maps,
                [mapTopic]: {
                    ...state.maps[mapTopic],
                    order,
                },
            };
        },
        updateMapOpacity(state: State, mapInfo: MapInfo) {
            const { mapTopic, opacity } = mapInfo;

            state.maps = {
                ...state.maps,
                [mapTopic]: {
                    ...state.maps[mapTopic],
                    opacity,
                },
            };
        },
        updateMapButton(state: State, mapInfo: MapInfo) {
            const { mapTopic, button_visible } = mapInfo;

            state.maps = {
                ...state.maps,
                [mapTopic]: {
                    ...state.maps[mapTopic],
                    button_visible,
                },
            };
        },
        updateMapUrl(state: State, mapInfo: MapInfo) {
            const { mapTopic, imageUrl } = mapInfo;
            state.maps = {
                ...state.maps,
                [mapTopic]: {
                    ...state.maps[mapTopic],
                    imageUrl,
                },
            };
        },
        updateMapJson(state: State, mapInfo: MapInfo) {
            const { mapTopic, data } = mapInfo;
            state.maps = {
                ...state.maps,
                [mapTopic]: {
                    ...state.maps[mapTopic],
                    data,
                },
            };
        },
        updateMapInterest(state: State, mapInfo: MapInfo) {
            const { mapTopic, interest } = mapInfo;
            state.maps = {
                ...state.maps,
                [mapTopic]: {
                    ...state.maps[mapTopic],
                    interest,
                },
            };
        },
        addMapMark(state: State, mapMark: any) {
            state.map_marks = [...state.map_marks, mapMark];
        },
        updateEnableKillSwitch(state: State, isEnabled: boolean) {
            state.enableKillSwitch = isEnabled;
        },
        updateIsAdmin(state: State, isAdmin: boolean) {
            state.isAdmin = isAdmin;
        },
        updateAllowedToDrive(state: State, isAllowed: boolean) {
            state.allowedToDrive = isAllowed;
        },
        updateEnableSidebar(state: State, isEnabled: boolean) {
            state.enableSidebar = isEnabled;
        },
        updateRoverStatus(state: State, status: RoverStatusData) {
            const batteryCharge = status.charge_estimate.value * 100;
            const batteryVoltage = status.battery_voltage.value;
            const eStopAlert = status.e_stop.value;

            state.rover.battery_charge = status.charge_estimate.value;
            state.rover.battery_voltage = status.battery_voltage.value;
            state.rover.e_stop = status.e_stop.value;

            state.rover.battery_charge_warning = batteryCharge < 25 ? "danger" : batteryCharge < 40 ? "caution" : "";
            state.rover.battery_charge_voltage =
                batteryVoltage < 24.2 ? "danger" : batteryVoltage < 24.6 ? "caution" : "";
            state.rover.e_stop_warning = eStopAlert ? "caution" : "";

            checkTelemetryWarnings(state);
        },
        setWindowSize(state: State, size: Vector2) {
            state.window_size = size;
        },
        addRequest(state: State, request_topic: string) {
            state.requests.push(request_topic);
        },
        clearRequests(state: State) {
            state.requests = [];
        },
    },
    actions: {},
});

function checkTelemetryWarnings(state: State): void {
    const telemetry_settings = [
        state.rover.pitch_alert,
        state.rover.roll_alert,
        state.rover.battery_charge_warning,
        state.rover.battery_charge_voltage,
        state.rover.e_stop_warning,
    ];

    //check if even one setting is issuing warning
    state.rover.telemetry_warning = telemetry_settings.some((setting) => setting === "danger")
        ? "danger"
        : telemetry_settings.some((setting) => setting === "caution")
        ? "caution"
        : "";
}
