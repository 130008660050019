// DART url is configured through env variable VUE_APP_DART_URL

export const DartTopics = {
    front_topic: "front-camera",
    depth_topic: "depth-camera",
    hazard_topic: "hazard-overlay",
    terrain_topic: "terrain-overlay",
    path_topic: "path-overlay",
    distance_topic: "distance-overlay",
    distance_labels_topic: "distance-labels",
    grid_topic: "grid-overlay",
    grid_labels_topic: "grid-labels",
    elevation_topic: "elevation-overlay",
    tracks_topic: "rover-tracks",
    zed_cam_settings_topic: "camera-settings",
    get_zed_cam_settings_topic: "get-zed-cam-settings",
    led_headlight_topic: "led-headlight-state",
    get_led_headlight_topic: "get-led-headlight-state",
    ptz_overlay_topic: "ptz-overlay",
    ptz_control_topic: "ptz-control",
    ptz_stream_topic: "ptz-image",
    ptz_import_pic: "ptz-import-pic",
    ptz_request_quality_image: "ptz-request-quality-image",
    ptz_info_topic: "ptz-info",
    ptz_centre_pose_topic: "ptz-centre-to-world",
    ptz_camera_params_topic: "ptz-camera_params",
    driveline_topic: "driveline-data",
    driving_command_topic: "driving-command",
    wheel_odometry_topic: "wheel-odometry-data",
    visual_odometry_topic: "visual-odometry-data",
    local_pose_topic: "local-pose",
    imu_topic: "imu",
    slip_topic: "slip",
    objects_topic: "objects",
    path_pixel_topic: "path-pixel-coords",
    map_topic: "map",
    terrain_map_topic: "terrain-map",
    hazard_map_topic: "hazard-map",
    elevation_map_topic: "elevation-map",
    grid_map_topic: "grid-map",
    fov_map_topic: "fov-map",
    ptz_fov_map_topic: "ptz-fov-map",
    map_mark_command_topic: "mark-map-command",
    map_mark_topic: "map-marks",
    map_coordinates_topic: "map-coordinates",
    path_upload_topic: "path-upload",
    path_coordinates_topic: "path-coordinates",
    autonomy_state_topic: "autonomy-state",
    path_telemetry_topic: "path-telemetry",
    gps_topic: "gps-data",
    rover_status_topic: "rover-status",
    overlay_map_topic: "overlay-map",
    lidar_topic: "points",
    lidar_float_buf_topic: "points-float-buf",
    lidar_short_buf_topic: "points-short-buf",
    svn_status_topic: "svn-status-data",
    request_zed_topic: "request-rgb",
};

export const JpgImageTopics = [
    DartTopics.front_topic,
    DartTopics.depth_topic,
    DartTopics.terrain_topic,
    DartTopics.elevation_topic,
];

export const Lz4ImageTopics = [
    DartTopics.hazard_topic,
    DartTopics.distance_topic,
    DartTopics.grid_topic,
    DartTopics.ptz_overlay_topic,
    DartTopics.tracks_topic,
];
