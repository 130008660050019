import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, withModifiers as _withModifiers, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-579fb45a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["aria-checked"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("span", {
      class: "toggle-wrapper",
      role: "checkbox",
      "aria-checked": _ctx.value,
      tabindex: "0",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args))),
      onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.toggle && _ctx.toggle(...args)), ["prevent"]), ["space"]))
    }, [
      _createElementVNode("span", {
        class: _normalizeClass(["toggle-background", _ctx.backgroundStyles])
      }, null, 2),
      _createElementVNode("span", {
        class: "toggle-indicator",
        style: _normalizeStyle(_ctx.indicatorStyles)
      }, null, 4)
    ], 40, _hoisted_1)
  ]))
}