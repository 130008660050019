
import { defineComponent } from "vue";
import { overlay_labels } from "../../../rover/index";
import * as rover_interface from "../../../rover/interface";
import Toggles from "./Toggles.vue";

export default defineComponent({
    name: "OverlayToggles",
    components: { Toggles },
    computed: {
        connected() {
            return this.$store.state.rover.connected;
        },
    },
    methods: {
        getLabel(key: string) {
            return overlay_labels[key];
        },
        availableOverlays() {
            return Object.keys(this.$store.state.images).filter((key) => this.$store.state.images[key].show_button);
        },
        getOverlayState(key: string) {
            return this.$store.state.images[key].show;
        },
        toggleOverlay(key: string) {
            this.$store.state.images[key].show = !this.$store.state.images[key].show;

            if (this.$store.state.images[key].show) {
                let interest = rover_interface.collectImage(key, this.$store.state.image_callback);
                this.$store.commit("updateImageInterest", {
                    topic: key,
                    interest,
                });
            } else {
                rover_interface.uncollect(this.$store.state.images[key].interest);
            }
        },
    },
});
