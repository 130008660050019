export interface PtzInterfacePitchYaw {
    pitch_rate: number;
    yaw_rate: number;
}

export interface PtzMoveAbsolute {
    yaw: number;
    pitch: number;
}

export interface PtzInfoInterface {
    scale_label: string;
    scale_length: number;
    in_zed_fov: boolean;
    unaltered_zoom: number;
    unaltered_focus: number;
    unaltered_exposure: number;
    autofocus: boolean;
    autoexposure: boolean;
    new_data: boolean;
}

export interface PtzCentrePose {
    position: number[];
}

export function anglePercentToPitchYaw(angle: number, percent: number): PtzInterfacePitchYaw {
    const angle_radians = (angle * Math.PI) / 180;
    const valid_percent = Math.min(100, Math.max(0, percent));
    const percentage_normalized = valid_percent / 100;
    const pitch_rate = percentage_normalized * Math.cos(angle_radians);
    const yaw_rate = percentage_normalized * Math.sin(angle_radians);
    return {
        pitch_rate: Math.abs(pitch_rate) < 0.1 ? 0 : pitch_rate,
        yaw_rate: Math.abs(yaw_rate) < 0.1 ? 0 : yaw_rate,
    };
}
