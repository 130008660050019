
import { defineComponent } from "vue";
import { overlayConfig, uniCodes } from "../../rover/overlayConfig";
import type { Vector2 } from "../../types";
import { getBoxSize } from "../../img_utils";

export default defineComponent({
    name: "GotoOverlay",
    data() {
        return {
            uniCodes,
            overlayConfig: overlayConfig.pathOverlay,
        };
    },
    computed: {
        camera() {
            return this.$store.state.overlays.camera;
        },
        window_size() {
            return this.$store.state.window_size;
        },
        path_viewbox() {
            return `0 0 ${this.$store.state.overlays.camera.size.width} ${this.$store.state.overlays.camera.size.height}`;
        },
        getBoxSizeX() {
            return getBoxSize(this.$store.state.window_size)[0];
        },
        getBoxSizeY() {
            return getBoxSize(this.$store.state.window_size)[1];
        },
        text_viewbox() {
            const box_size = getBoxSize(this.$store.state.window_size);
            return `0 0 ${box_size[0]} ${box_size[1]}`;
        },
        path_pixel_coords() {
            return this.$store.state.overlays.path.path_pixel_coords;
        },
        path_displacement() {
            return this.$store.state.overlays.path.path_displacement;
        },
        last_coord() {
            const path_coords = this.$store.state.overlays.path.path_pixel_coords;
            const last_index = path_coords.length - 1;
            return path_coords[last_index];
        },
        path_error() {
            return this.$store.state.overlays.path.error_message;
        },
        connected() {
            return this.$store.state.rover.connected;
        },
    },
    methods: {
        round(num: number, decimal_places: number) {
            return parseFloat(num.toFixed(decimal_places));
        },
        /* Compute the points for the SVG path => for debugging I suggest drawing circle for each point.*/
        computeSVGPoints() {
            // The pixel positions of path poses: start at rover base and travel to goal pose.
            const path = this.$store.state.overlays.path.path_pixel_coords;
            // Consider the path as a series of rectangles. The right edge, with greater x,
            // will be denoted the "right" edge. The other is the "left" edge.
            const rightPoints = [];
            const leftPoints = [];

            const MIN_WIDTH = 1;

            /**
             * Given a series of pixel points in the path, we generate two outline points to account for width.
             * These points will be at +- 0.5 * width from the current point. The line joining
             * the points should be normal to the path.
             *
             * Let our line segment be composed of the current start point joined to the next point
             * in the path. The path begins at the rover's base.
             *
             * First compute the absolute angle φ of the current segment relative to the horizontal.
             * We then define θ to be φ - 90. This effectively makes our angle
             * relative to the positive-y axis. i.e \| => +φ and |/ => -φ.
             *       > The vertical is now 0deg.
             *       > Left (CCW) from the vertical is positive.
             *       > Right (CW) from the vertical is negative.
             *
             * Let the next point in the path be P, we need to create two
             * points - to the left and right - that are normal to the line joining P and the point before it.
             * The right point is P_r, the left point is P_l.
             * The width of the path is w = the distance between P_r and P_l.
             *
             * Considering the right point first,
             *
             * Draw a line joining P and P_r. The angle between this line and the horizontal axis
             * is equal to θ. Hence, P_r is given by:
             *
             * P_r = 〈P_x + 0.5 * w * cosθ, P_y - 0.5 * w * sinθ〉
             * The x point is to the right so we add it.
             * If the path is angled left, θ > 0 => sinθ > 0. P_r is
             * above P so the y coordinate is decreased. If the path is
             * angled right  θ < 0 => sinθ < 0. Our P_r is below P so y is increased.
             * This means the y offset is negated.
             *
             * By similar logic, P_l can be acquired by mirroring P_r across the path line: this means
             * we do the opposite of what we did to P_r by negating those offsets to get:
             *
             * P_l = 〈P_x - 0.5 * w * cosθ, P_y + 0.5 * w * sinθ〉
             *
             * Everything is negated automatically if the path is towards the camera since dy < 0.
             */

            // The left point is given by P_l = (Px + 0.5 * w * cos(φ), Py - 0.5 * w * sin(φ))
            const leftPoint = (point: Vector2, heading: number, width: number): { x: number; y: number } => ({
                x: point.x - width * Math.cos(heading),
                y: point.y + width * Math.sin(heading),
            });

            // The right point is given by P_r = (Px - 0.5 * w * cos(φ), Py + 0.5 * w * sin(φ))
            const rightPoint = (point: Vector2, heading: number, width: number): { x: number; y: number } => ({
                x: point.x + width * Math.cos(heading),
                y: point.y - width * Math.sin(heading),
            });

            let last_width = null; // Store the last width so that connecting line segments are the same width.
            // Generate the points.
            for (const idx in path) {
                const { start, end } = path[idx];
                let half_end_width = path[idx].width / 4;
                let half_start_width = last_width ? last_width : half_end_width;
                /*
                    We use φ = arctan2(-y/x) instead of Arctan which will handle direction for us. 
                    Note that arctan(x) ∈ [0, 2π] but Arctan(x) ∈ [-π/2, π/2].
                */
                const dx = end.x - start.x;
                const dy = end.y - start.y;
                const phi = Math.atan2(-dy, dx);
                const theta = phi - Math.PI / 2;

                // Clamp the width so the line doesn't disappear when far away.
                if (half_end_width < MIN_WIDTH) {
                    half_end_width = MIN_WIDTH;
                }

                const start_right = rightPoint(start, theta, half_start_width);
                const start_left = leftPoint(start, theta, half_start_width);
                const end_right = rightPoint(end, theta, half_end_width);
                const end_left = leftPoint(end, theta, half_end_width);

                rightPoints.push(start_right, end_right);
                leftPoints.push(start_left, end_left);
                last_width = half_end_width;
            }

            // The svg path connects points sequentially and then fills the inside.
            // Ours will connect the right points, then the left points. So the first
            // left point connected will be at the end of the path.
            leftPoints.reverse();

            return { rightPoints, leftPoints };
        },
        /* Construct the SVG path d attribute */
        computeSVGPath() {
            let svgPath = "";
            const { rightPoints, leftPoints } = this.computeSVGPoints();

            const isInvalidPoint = (point: Vector2): boolean => isNaN(point.x) || isNaN(point.y);

            // Connect the right points then left points to construct path
            /*
                Note prefixes:
                M - move to: does not connect the line to previous pixel.
                L - also moves but connects the line.
            */
            rightPoints.map((point, idx) => {
                let prefix = "";
                if (idx === 0) {
                    prefix = "M";
                } else {
                    prefix = "L";
                }
                if (isInvalidPoint(point)) {
                    return;
                }
                svgPath += `${prefix}${point.x} ${point.y} `;
            });

            leftPoints.map((point) => {
                if (isInvalidPoint(point)) {
                    return;
                }
                svgPath += `L${point.x} ${point.y} `;
            });

            svgPath += "Z";

            return svgPath;
        },
        validPath() {
            const path_displacement = this.$store.state.overlays.path.path_displacement;
            const path = this.$store.state.overlays.path.path_pixel_coords;
            if (path_displacement === null || path === null) {
                return false;
            }
            if (path.length === 0) {
                return false;
            }

            return true;
        },
    },
});
