import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2c27d8a2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "overlay-labels" }
const _hoisted_2 = ["viewBox"]
const _hoisted_3 = ["fill", "stroke", "stroke-width", "x", "y", "font-size", "fill-opacity", "stroke-opacity"]
const _hoisted_4 = ["fill", "stroke", "stroke-width", "x", "y", "font-size", "fill-opacity", "stroke-opacity"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock("svg", {
      height: "100%",
      width: "100%",
      viewBox: _ctx.svgViewbox,
      preserveAspectRatio: "xMidYMid",
      class: "labels",
      style: _normalizeStyle({ visibility: _ctx.connected ? 'visible' : 'hidden' })
    }, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.distance_labels, (position, label) => {
        return (_openBlock(), _createElementBlock("text", {
          key: label,
          class: "overlay-label",
          fill: _ctx.distanceConfig.textFill,
          stroke: _ctx.distanceConfig.textOutline,
          "stroke-width": _ctx.distanceConfig.outlineThickness,
          x: (position.x * _ctx.getBoxSizeX) / _ctx.camera.size.width,
          y: (position.y * _ctx.getBoxSizeY) / _ctx.camera.size.height,
          "font-size": _ctx.distanceConfig.fontSize,
          "font-weight": "bold",
          "fill-opacity": _ctx.distanceConfig.opacity,
          "stroke-opacity": _ctx.distanceConfig.opacity
        }, _toDisplayString(label), 9, _hoisted_3))
      }), 128)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.grid_labels, (position, label) => {
        return (_openBlock(), _createElementBlock("text", {
          key: label,
          class: "overlay-label",
          fill: _ctx.gridConfig.textFill,
          stroke: _ctx.gridConfig.textOutline,
          "stroke-width": _ctx.gridConfig.outlineThickness,
          x: (position.x * _ctx.getBoxSizeX) / _ctx.camera.size.width,
          y: (position.y * _ctx.getBoxSizeY) / _ctx.camera.size.height,
          "font-size": _ctx.gridConfig.fontSize,
          "font-weight": "bold",
          "fill-opacity": _ctx.gridConfig.opacity,
          "stroke-opacity": _ctx.gridConfig.opacity
        }, _toDisplayString(label), 9, _hoisted_4))
      }), 128))
    ], 12, _hoisted_2))
  ]))
}