
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        isSelected: {
            type: Boolean,
        },
    },
});
