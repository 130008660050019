<template>
    <div id="app" class="light">
        <router-view class="app-content"></router-view>
    </div>
</template>

<style lang="scss" scoped>
// Import Nanum Gothic Coding Font.
@font-face {
    font-family: "NanumGothicCoding";
    // IE9 Compat mode
    src: url("./assets/fonts/NanumGothicCoding.eot"); /* IE9 Compat Modes */
    // IE6-IE8
    src: url("./assets/fonts/NanumGothicCoding.eot?#iefix") format("embedded-opentype"),
        // Modern Browsers
        url("./assets/fonts/NanumGothicCoding.woff2") format("woff2"),
        // More Modern Browsers
        url("./assets/fonts/NanumGothicCoding.woff") format("woff"),
        // Safari, Android, iOS
        url("./assets/fonts/NanumGothicCoding-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "NanumGothicCoding";
    // IE9 Compat mode
    src: url("./assets/fonts/NanumGothicCoding-Bold.eot");
    // IE6-IE8
    src: url("./assets/fonts/NanumGothicCoding-Bold.eot?#iefix") format("embedded-opentype"),
        // Modern Browsers
        url("./assets/fonts/NanumGothicCoding-Bold.woff2") format("woff2"),
        // More Modern Browsers
        url("./assets/fonts/NanumGothicCoding-Bold.woff") format("woff"),
        // Safari, Android, iOS
        url("./assets/fonts/NanumGothicCoding-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100vw;
    height: 100vh;
    position: relative;
}
.app-content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}
</style>
