// Utilities for working with quaternions.

import type { Quaternion } from "../types";

/**
 * Convert a quaternion to Euler angles, in the order [roll, pitch, yaw].
 * See https://en.wikipedia.org/wiki/Conversion_between_quaternions_and_Euler_angles.
 * @param quaternion Quaternion to convert.
 */
export default function quaternionToYpr(quaternion: Quaternion): [number, number, number] {
    const [x, y, z, w] = quaternion;
    // Negate pitch and yaw to change z to down and y to right
    return [
        Math.atan2(2 * (w * x + y * z), 1 - 2 * (x * x + y * y)),
        -Math.asin(2 * (w * y - z * x)),
        -Math.atan2(2 * (w * z + x * y), 1 - 2 * (y * y + z * z)),
    ];
}
