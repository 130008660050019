// Hex colour codes for convenience
const colours = {
    WHITE: "#ffffff",
    BLACK: "#000000",
    DARK_GREY: "#404040",
    RED: "#ff0000",
    GREEN: "#00ff00",
    YELLOW: "#ffff00",
    BLUE: "#0000ff",
};

// TODO: Probably not the place for this.
const uniCodes = {
    phasorAngle: "\u2220",
    degree: "\u00b0",
};

// Pull from store if we want to make it dynamic later.
const overlayConfig = {
    // *Outlines are not currently used
    pathOverlay: {
        opacity: "0.7",
        pathFill: colours.WHITE,
        pathOutline: colours.WHITE,
        lastPoseFill: colours.DARK_GREY,
        textFill: colours.WHITE,
        textOutline: colours.BLACK,
        strokeJoin: "round", // Rounded outlines for path
        PathOutlineThickness: "1.5",
    },
    distance: {
        // Labels only
        fontSize: "1.6em",
        textFill: colours.WHITE,
        textOutline: colours.BLACK,
        outlineThickness: "1",
        opacity: "0.7",
    },
    grid: {
        // Labels only
        fontSize: "1.6em",
        textFill: colours.WHITE,
        textOutline: colours.BLACK,
        outlineThickness: "0.7",
        opacity: "0.7",
    },
    mapMarks: {
        fill: colours.YELLOW,
        labelFill: colours.YELLOW,
        opacity: "0.7",
    },
};

export { colours, overlayConfig, uniCodes };
