// This file contains utility functions for processing the gamepad
// controller inputs

import { HTML5GamepadState } from "@mcss/gamepad";

/** Press this button to activate the controller **/
export const DEADMAN_BUTTON = "LEFT_TRIGGER";

/** The gamepad inputs processed locally **/
export interface ControllerInput {
    LEFT_STICK_X: number;
    LEFT_STICK_Y: number; // linear
    RIGHT_STICK_Y: number;
    RIGHT_STICK_X: number; // angular
}

/** Inputs sent over DART, used to drive the rover */
export interface Velocity {
    linear: number;
    angular: number;
}

/**
 * Compute the velocity given the controller inputs.
 * It filters out noise by setting a minimum threshold
 * before the inputs are processed.
 * @param controller
 */
function computeVelocity(controller: ControllerInput): Velocity {
    if (controller === undefined) {
        return {
            linear: 0,
            angular: 0,
        };
    }

    // Minimum input to consider the stick to be active
    // this accounts for noise
    const THRESHOLD = 0.1;

    const leftStickY = Math.abs(controller.LEFT_STICK_Y) > THRESHOLD ? -controller.LEFT_STICK_Y : 0;
    const rightStickX = Math.abs(controller.RIGHT_STICK_X) > THRESHOLD ? -controller.RIGHT_STICK_X : 0;

    return { linear: leftStickY, angular: rightStickX };
}

/**
 * Ensure a minimum threshold on the sticks.
 */
function thresholdCheck(state: ControllerInput): ControllerInput {
    // Limit threshold.
    const { linear, angular } = computeVelocity(state);

    const LEFT_STICK_Y = linear;
    const RIGHT_STICK_X = angular;

    return { ...state, LEFT_STICK_Y, RIGHT_STICK_X };
}

/*
 * This will reset the stick values to 0.
 */
function resetSticks(state: ControllerInput): ControllerInput {
    const velocityState = {
        LEFT_STICK_Y: 0,
        LEFT_STICK_X: 0,
        RIGHT_STICK_X: 0,
        RIGHT_STICK_Y: 0,
    };

    return { ...state, ...velocityState };
}

function checkDeadman(state: HTML5GamepadState): boolean {
    if (state[DEADMAN_BUTTON]) {
        return true;
    }

    return false;
}

/**
 * Takes the gamepad input and returns an object
 * that only has the properties which are sent over DART.
 * @param state input from gamepad service
 */
function trimInput(state: ControllerInput): Velocity {
    return {
        linear: state.LEFT_STICK_Y,
        angular: state.RIGHT_STICK_X,
    };
}

export { computeVelocity, thresholdCheck, resetSticks, checkDeadman, trimInput };
