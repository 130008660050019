
import { defineComponent } from "vue";
import ToggleButton from "./ToggleButton.vue";

export default defineComponent({
    name: "Toggles",
    components: { ToggleButton },
    props: {
        getAvailableTopics: { type: Function, required: true },
        getTopicState: { type: Function, required: true },
        getLabel: { type: Function, required: true },
        toggleTopic: { type: Function, required: true },
        title: { type: String, required: true },
    },
    computed: {
        connected() {
            return this.$store.state.rover.connected;
        },
    },
});
