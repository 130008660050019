import { createRouter, createWebHistory } from "vue-router";
import Login from "./views/Login.vue";
import Main from "./views/Main.vue";
import LidarViewer from "./views/LidarViewer.vue";
import PTZViewer from "./views/PTZViewer.vue";
import MapViewer from "./views/MapViewer.vue";
import getKratosApi from "./kratos";
import store from "./store";
import login from "./kratos/login";

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: "/login",
            name: "login",
            component: Login,
        },
        {
            path: "/",
            name: "main",
            component: Main,
        },
        {
            path: "/lidar",
            name: "lidar",
            component: LidarViewer,
        },
        {
            path: "/ptz",
            name: "ptz",
            component: PTZViewer,
        },
        {
            path: "/map",
            name: "map",
            component: MapViewer,
        },
        {
            path: "/:pathMatch(.*)",
            redirect: { name: "login" },
        },
    ],
});

router.beforeEach(async (to, from, next) => {
    if (process.env.VUE_APP_DISABLE_AUTHENTICATION === "true") {
        if (process.env.VUE_APP_USER_EMAIL === undefined) {
            throw new Error("VUE_APP_USER_EMAIL needs to be set if you want to disable authentication.");
        }

        login(process.env.VUE_APP_USER_EMAIL, "", "");
        next();
    } else {
        // Determine if the user is authenticated.
        const kratos = await getKratosApi();
        try {
            const { data } = await kratos.toSession();
            login(data.identity.traits.email, data.identity.traits.name.first, data.identity.traits.name.last);
            next();
        } catch {
            store.commit("logout");
            if (to.matched.some((record) => record.path === "/login")) next();
            else next({ name: "login", query: to.query });
        }
    }
});

export default router;
