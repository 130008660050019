/**
 * This file contains utility functions for serializing
 * and deserializing data to and from ArrayBuffers.
 *
 * DART only supports sending and receiving ArrayBuffers.
 */

/**
 * Encodes a JSON object into a string Buffer.
 * @param json the object to encode.
 */
function encodeJSON(json: unknown): Uint8Array {
    const enc = new TextEncoder();
    const str = JSON.stringify(json);
    const data: Uint8Array = enc.encode(str);
    return data;
}

/**
 * Decodes an ArrayBuffer into a JSON object.
 * @param buffer data to decode.
 */
function decodeJSON(arr: ArrayBuffer): unknown {
    const buffer = Buffer.from(arr);
    const json = buffer.toJSON();
    return json;
}

export { encodeJSON, decodeJSON };
