
import { defineComponent } from "vue";
import { roverDisabled } from "../rover/interface";
import type Joystick from "../joystick";
import JoystickComponent from "./Joystick.vue";

export default defineComponent({
    components: { JoystickComponent },
    methods: {
        onDrag(joystick: Joystick) {
            // in range [-1,1] and filter for noise since joystick is highly sensitive
            let angular =
                -(joystick.shaft.current.percentage * Math.sin((joystick.shaft.current.angle * Math.PI) / 180)) / 100;
            angular = Math.abs(angular) < 0.1 ? 0 : angular;
            let linear =
                (joystick.shaft.current.percentage * Math.cos((joystick.shaft.current.angle * Math.PI) / 180)) / 100;
            if (this.isAdmin()) {
                linear = Math.abs(linear) < 0.1 ? 0 : linear;
            } else {
                // Only support forward movement
                linear = linear < 0.1 ? 0 : linear;
            }
            // publish to the UI
            this.$store.commit("input", {
                moving: linear !== 0 || angular !== 0,
                linear,
                angular,
            });
        },
        onDeactivate() {
            this.$store.commit("input", {
                moving: false,
                linear: 0,
                angular: 0,
            });
            document.body.removeAttribute("style");
        },
        // this function prevents activation if Gamepad is connected and L2 is pressed
        // or if the driving state is disabled and you are not an admin
        overrideActivate() {
            return this.$store.state.rover.gamepad || (roverDisabled() && !this.$store.state.isAdmin);
        },
        isAdmin() {
            if (this.$store === null) {
                return false;
            }
            return this.$store.state.isAdmin;
        },
    },
});
