import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-18ad7a00"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "goto-overlay" }
const _hoisted_2 = ["viewBox"]
const _hoisted_3 = ["fill", "fill-opacity", "d"]
const _hoisted_4 = ["fill", "cx", "cy", "r"]
const _hoisted_5 = ["viewBox"]
const _hoisted_6 = ["fill", "x", "y", "stroke", "fill-opacity"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(["path-error-message", { show: _ctx.path_error !== null }])
    }, [
      _createElementVNode("text", null, _toDisplayString(_ctx.path_error), 1)
    ], 2),
    (_ctx.validPath())
      ? (_openBlock(), _createElementBlock("svg", {
          key: 0,
          height: "100%",
          width: "100%",
          viewBox: _ctx.path_viewbox,
          preserveAspectRatio: "none",
          class: "svg-overlay",
          style: _normalizeStyle({ visibility: _ctx.connected ? 'visible' : 'hidden' })
        }, [
          _createElementVNode("path", {
            fill: _ctx.overlayConfig.pathFill,
            "fill-opacity": _ctx.overlayConfig.opacity,
            d: _ctx.computeSVGPath()
          }, null, 8, _hoisted_3),
          (_ctx.last_coord)
            ? (_openBlock(), _createElementBlock("circle", {
                key: 0,
                fill: _ctx.overlayConfig.lastPoseFill,
                cx: _ctx.last_coord.end.x,
                cy: _ctx.last_coord.end.y,
                r: _ctx.last_coord.width
              }, null, 8, _hoisted_4))
            : _createCommentVNode("", true)
        ], 12, _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.validPath())
      ? (_openBlock(), _createElementBlock("svg", {
          key: 1,
          id: "svg-text-overlay",
          height: "100%",
          width: "100%",
          style: _normalizeStyle({ visibility: _ctx.connected ? 'visible' : 'hidden' }),
          viewBox: _ctx.text_viewbox,
          preserveAspectRatio: "none",
          class: "svg-overlay"
        }, [
          _createElementVNode("text", {
            fill: _ctx.overlayConfig.textFill,
            x: `${(_ctx.last_coord.end.x * _ctx.getBoxSizeX) / _ctx.camera.size.width}`,
            y: `${(_ctx.last_coord.end.y * _ctx.getBoxSizeY) / _ctx.camera.size.height - 20}`,
            "font-size": "1.6em",
            "font-weight": "bold",
            stroke: _ctx.overlayConfig.textOutline,
            "fill-opacity": _ctx.overlayConfig.opacity
          }, _toDisplayString(`(D: ${_ctx.round(_ctx.path_displacement.magnitude, 2)} m, ` +
                    `H: ${_ctx.round(_ctx.path_displacement.height, 2)} m)` +
                    `${_ctx.uniCodes.phasorAngle}${_ctx.round(_ctx.path_displacement.heading, 2)} ${_ctx.uniCodes.degree}`), 9, _hoisted_6)
        ], 12, _hoisted_5))
      : _createCommentVNode("", true)
  ]))
}