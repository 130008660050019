import html2canvas from "html2canvas";
import { saveAs } from "file-saver";
import { Ref, nextTick } from "vue";
import guid from "./rover/guid";
import { DartTopics } from "./dart_constants";
import * as rover_interface from "./rover/interface";
import { encodeJSON } from "./rover/network_protocol";
import Ptz from "./components/Ptz.vue";
import store from "./store";

function getImageFilename(cam_name: string, centre_x: number, centre_y: number): string {
    const time = new Date();
    // colons are forbidden in Windows filenames
    const time_formatted = time.toLocaleTimeString().replace(/:/g, ".").replace(/\s+/g, "_");
    const location_formatted = `${centre_x.toFixed(2)},${centre_y.toFixed(2)}`;
    return `${cam_name}_${time_formatted}_${location_formatted}.jpg`;
}

function exportImgAll(): void {
    const [x, y] = store.state.rover.visual_odometry_position;
    const filename = getImageFilename("all", x, y);

    rover_interface.setFreezeImageURLRevoke(true);
    // TODO: Was this needed? Seems to work fine without it
    // contextMenu.value?.closeMenu();
    // Wait for right click menu to close in the next tick
    nextTick().then(() => {
        const mainView = document.getElementById("main-view");
        if (mainView === null) return;
        html2canvas(mainView)
            .then((canvas) => {
                try {
                    canvas.toBlob((b: Blob | null) => {
                        if (b === null) return;
                        saveAs(b, filename);
                        rover_interface.setFreezeImageURLRevoke(false);
                    });
                } catch (error) {
                    rover_interface.setFreezeImageURLRevoke(false);
                }
            })
            .catch(() => {
                rover_interface.setFreezeImageURLRevoke(false);
            });
    });
}

async function exportImgPtz(PTZView: Ref<typeof Ptz | null>): Promise<void> {
    const [x, y] = store.state.ptz_pose.centre_position_world;
    const filename = getImageFilename("ptz", x, y);

    try {
        await PTZView.value?.saveAs();

        if (!store.state.ptz_info.in_zed_fov) {
            alert(
                "Warning: PTZ is not in ZED camera view. Image location cannot be determined, " +
                    "so rover location will be used instead on map."
            );
        }

        const mark_command = {
            id: guid(),
            world_location: { x, y },
            label: filename,
            mark_type: "ptz",
        };
        store.commit("addMapMark", mark_command);
        rover_interface.sendData(DartTopics.map_mark_command_topic, encodeJSON(store.state.map_marks));
    } catch (error) {
        console.error(error);
        alert("Error downloading PTZ image...");
    }
}

// Use the window size to calculate the maximum size
// of a 16/9 box that will fit inside of it
function getBoxSize(window_size: { x: number; y: number }): number[] {
    const calc_h = window_size.x * (9 / 16);
    const calc_w = window_size.y * (16 / 9);
    // Must reduce height to 16/9
    if (calc_h < window_size.y) {
        return [window_size.x, calc_h];
    }
    // Must reduce width to 16/9
    if (calc_w < window_size.x) {
        return [calc_w, window_size.y];
    }
    // Just return the window size
    return [window_size.x, window_size.y];
}

export { exportImgAll, exportImgPtz, getBoxSize, getImageFilename };
