
import { defineComponent } from "vue";
import { JpgImageTopics, Lz4ImageTopics } from "../dart_constants";

export default defineComponent({
    name: "CameraView",
    computed: {
        showWelcomeText() {
            // Show if the rover is not connected.
            return !this.$store.state["rover"].connected;
        },
        getJpgImages() {
            const images = { ...this.$store.state.images };

            return Object.keys(images)
                .filter((key) => JpgImageTopics.includes(key))
                .map((key) => ({
                    imageUrl: images[key].imageUrl,
                    show: images[key].show,
                    opacity: images[key].opacity,
                    order: images[key].order,
                    id: images[key].label + "-image",
                }))
                .sort((a, b) => a.order - b.order);
        },
        getLz4Images() {
            const images = { ...this.$store.state.images };

            return Object.keys(images)
                .filter((key) => Lz4ImageTopics.includes(key))
                .map((key) => ({
                    imageUrl: images[key].imageUrl,
                    show: images[key].show,
                    opacity: images[key].opacity,
                    order: images[key].order,
                    id: images[key].label + "-image",
                }))
                .sort((a, b) => a.order - b.order);
        },
        connected() {
            return this.$store.state.rover.connected;
        },
        objects() {
            return this.$store.state.rover.objects;
        },
    },
});
