import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5ea646c9"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "overlay-toggle"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_toggle_button = _resolveComponent("toggle-button")!

  return (_ctx.connected)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("h2", null, _toDisplayString(_ctx.title), 1),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getAvailableTopics(), (key, index) => {
          return (_openBlock(), _createElementBlock("div", {
            key: index,
            class: "toggle-row"
          }, [
            _createVNode(_component_toggle_button, {
              value: _ctx.getTopicState(key),
              onInput: ($event: any) => (_ctx.toggleTopic(key))
            }, null, 8, ["value", "onInput"]),
            _createElementVNode("p", null, _toDisplayString(_ctx.getLabel(key)), 1)
          ]))
        }), 128))
      ]))
    : _createCommentVNode("", true)
}