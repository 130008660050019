
import { defineComponent } from "vue";
import { sendPath, parsePoints, pathType } from "../rover/pathUpload";
import { CSVHelper } from "../rover/CSVHelper";

export default defineComponent({
    name: "PathUpload",
    data() {
        return {
            inputPoints: "",
            selectMessage: "",
        };
    },
    computed: {
        disableDrive() {
            return !this.$store.state.allowedToDrive;
        },
    },
    methods: {
        // TODO: Optimize filtering everywhere.
        // Send buffer containing point sequence from textbox.
        uploadPoints() {
            // Textbox points should each be on a seperate line.
            const filteredPoints = this.inputPoints
                .split("\n")
                .filter((elem) => CSVHelper.isCartestian(elem.replace(/[()]/g, "").split(",")));

            const pointBuffer = parsePoints(filteredPoints);
            if (!pointBuffer) {
                this.selectMessage = "Invalid Input";
                setTimeout(() => {
                    this.selectMessage = "";
                }, 2000);
                return;
            }

            this.sendBuffer(pointBuffer);
        },
        // Obtain csv.
        uploadCSV(e: Event) {
            if (!(e.target instanceof HTMLInputElement) || e.target.files === null) return;
            let file = e.target.files[0];
            if (!file) return;

            CSVHelper.getAsText(file).then((csvData) => {
                this.inputPoints = csvData.join("\n");
            });
        },
        // Sends point buffer to path-collector
        sendBuffer(pointBuffer: Float32Array) {
            const msg = { type: pathType.SERIES, pointBuffer };
            sendPath(msg);

            this.selectMessage = "Sent Path";
            setTimeout(() => {
                this.selectMessage = "";
            }, 2000);
        },
    },
});
