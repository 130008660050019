
import { defineComponent } from "vue";
import { map_labels } from "../../../rover/index";
import { DartTopics } from "../../../dart_constants";
import Toggles from "./Toggles.vue";

export default defineComponent({
    name: "MapOverlayToggles",
    components: { Toggles },
    emits: ["map-overlay-enable"],
    computed: {
        connected() {
            return this.$store.state.rover.connected;
        },
    },
    methods: {
        getLabel(map: Record<string, string>) {
            return map_labels[map.topic];
        },
        getMapState(map: Record<string, string>) {
            return map.isEnabled;
        },
        toggleMap(map: Record<string, string>) {
            const topic = map.topic;
            // Get the current state.
            const isEnabled = this.$store.state.maps[topic].isEnabled;

            // Toggle the state.
            this.$emit("map-overlay-enable", { topic, isEnabled: !isEnabled });
            this.$store.commit("updateMapEnabled", { mapTopic: topic, isEnabled: !isEnabled });
        },
        getMaps() {
            const maps = this.$store.state.maps;
            const processedMaps: {
                imageUrl: string;
                order: number;
                opacity: number;
                topic: string;
                isEnabled: boolean;
            }[] = [];

            // Hide the toggle for these topics
            const hideToggleButton = [DartTopics.fov_map_topic];

            // Adds defaults to maps
            Object.keys(maps).forEach((mapKey) => {
                const map = maps[mapKey];

                if (map.button_visible === true) {
                    if (hideToggleButton.includes(mapKey)) {
                        return;
                    }

                    processedMaps.push({
                        imageUrl: map.imageUrl,
                        order: map.order ? map.order : 1,
                        opacity: map.opacity ? map.opacity : 0.35,
                        topic: mapKey,
                        isEnabled: map.isEnabled !== undefined ? map.isEnabled : false,
                    });
                }
            });

            return processedMaps;
        },
    },
});
