import store from "../store";
import getConfig from "../config";

export default function login(username: string, first_name: string, last_name: string): void {
    store.commit("login", {
        email: username,
        first_name: first_name,
        last_name: last_name,
    });
    setPermissions(username);
}

// @todo: copying the original way how these were assigned.
//   This should be reworked to use proper authorization and permissions system [MCS-160]
async function setPermissions(username: string): Promise<void> {
    /* Update permissions for users. */
    const config = await getConfig();

    // Users logged in with these accounts will
    // not have any driving controls.
    const driving_disabled_emails = [
        "viewer@missioncontrolspaceservices.com",
        "science@missioncontrolspaceservices.com",
    ];

    // Users logged in with these accounts will
    // not see the right side bar
    const sidebar_disabled_emails = ["science@missioncontrolspaceservices.com"];

    const admin_emails = config["VUE_APP_ADMIN_EMAILS"];

    // Disable driving for viewer and science.
    if (driving_disabled_emails.includes(username)) {
        store.commit("updateAllowedToDrive", false);
    } else {
        // Needed in case someone signs in as viewer, but then
        // signs out and back in as a different user.
        store.commit("updateAllowedToDrive", true);
    }

    // Disable side bar for science.
    if (sidebar_disabled_emails.includes(username)) {
        store.commit("updateEnableSidebar", false);
    } else {
        // Needed in case someone signs in as viewer, but then
        // signs out and back in as a different user.
        store.commit("updateEnableSidebar", true);
    }

    // Enable the kill switch if user is admin. Disabled otherwise.
    if (admin_emails.includes(username)) {
        store.commit("updateIsAdmin", true);
        store.commit("updateEnableKillSwitch", true);
        store.commit("updatePTZEnableRestartButton", true);
    } else {
        store.commit("updateIsAdmin", false);
        store.commit("updateEnableKillSwitch", false);
        store.commit("updatePTZEnableRestartButton", false);
    }
}
