
import { defineComponent } from "vue";

export default defineComponent({
    name: "PanelToggle",
    data: () => ({
        isBurgerActive: true,
    }),
    methods: {
        toggle() {
            this.isBurgerActive = !this.isBurgerActive;
        },
    },
});
