import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a3eeaf9a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = { id: "distance-bars-wrapper" }
const _hoisted_3 = ["preserveAspectRatio", "viewBox"]
const _hoisted_4 = ["x", "y"]
const _hoisted_5 = ["x1", "y1", "x2", "y2"]
const _hoisted_6 = ["x1", "y1", "x2", "y2"]
const _hoisted_7 = ["x1", "y1", "x2", "y2"]
const _hoisted_8 = ["x1", "x2", "y1", "y2"]
const _hoisted_9 = ["x1", "x2", "y1", "y2"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    id: "ptz-feed",
    class: _normalizeClass(['ptz-feed-wrapper', _ctx.ptzType]),
    style: _normalizeStyle(_ctx.ptzStyle),
    onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.positionClick && _ctx.positionClick(...args)), ["stop","prevent"]))
  }, [
    _createElementVNode("img", {
      id: "videostream",
      src: _ctx.cameraUrl
    }, null, 8, _hoisted_1),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(), _createElementBlock("svg", {
        id: "distance-bars",
        width: "100%",
        height: "100%",
        preserveAspectRatio: _ctx.aspectRatio,
        style: _normalizeStyle({ visibility: _ctx.distance_indicator.show ? 'visible' : 'hidden' }),
        viewBox: _ctx.svgViewbox
      }, [
        _createElementVNode("text", {
          x: _ctx.distance_indicator.label_x,
          y: _ctx.distance_indicator.label_y,
          class: "svg-label",
          "text-anchor": "middle"
        }, _toDisplayString(_ctx.distance_indicator.label_text), 9, _hoisted_4),
        _createElementVNode("line", {
          x1: _ctx.distance_indicator.line_x1,
          y1: _ctx.distance_indicator.line_y1,
          x2: _ctx.distance_indicator.line_x2,
          y2: _ctx.distance_indicator.line_y2,
          stroke: "white",
          style: {"stroke-width":"5"}
        }, null, 8, _hoisted_5),
        _createElementVNode("line", {
          x1: _ctx.distance_indicator.line_x1 + 2.5,
          y1: _ctx.distance_indicator.line_y1,
          x2: _ctx.distance_indicator.line_x1 + 2.5,
          y2: _ctx.distance_indicator.line_y1 - 20,
          stroke: "white",
          style: {"stroke-width":"5"}
        }, null, 8, _hoisted_6),
        _createElementVNode("line", {
          x1: _ctx.distance_indicator.line_x2 - 2.5,
          y1: _ctx.distance_indicator.line_y2,
          x2: _ctx.distance_indicator.line_x2 - 2.5,
          y2: _ctx.distance_indicator.line_y2 - 20,
          stroke: "white",
          style: {"stroke-width":"5"}
        }, null, 8, _hoisted_7),
        _createElementVNode("line", {
          x1: _ctx.distance_indicator.crosshair_centre.x - _ctx.distance_indicator.crosshair_size / 2,
          x2: _ctx.distance_indicator.crosshair_centre.x + _ctx.distance_indicator.crosshair_size / 2,
          y1: _ctx.distance_indicator.crosshair_centre.y,
          y2: _ctx.distance_indicator.crosshair_centre.y,
          stroke: "white",
          style: {"stroke-width":"5"}
        }, null, 8, _hoisted_8),
        _createElementVNode("line", {
          x1: _ctx.distance_indicator.crosshair_centre.x,
          x2: _ctx.distance_indicator.crosshair_centre.x,
          y1: _ctx.distance_indicator.crosshair_centre.y - _ctx.distance_indicator.crosshair_size / 2,
          y2: _ctx.distance_indicator.crosshair_centre.y + _ctx.distance_indicator.crosshair_size / 2,
          stroke: "white",
          style: {"stroke-width":"5"}
        }, null, 8, _hoisted_9)
      ], 12, _hoisted_3))
    ])
  ], 6))
}