import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader.js";

// for generating colors based on height values
const BASE_COLOR = new THREE.Color("blue");
const TOP_COLOR = new THREE.Color("orange");
const MAX_HEIGHT_COLOR = 5;
const MIN_HEIGHT_COLOR = -3;

// for generating colors based on intensity values
const MINIMUM_INTENSITY_HUE: number[] = [0, 240]; // intensity, hue
const MAXIMUM_INTENSITY_HUE: number[] = [196, 0];

// defined as the positive offset of the LIDAR when compared with the model origin
const LIDAR_Z_OFFSET = 0.1524; // 3.75"
const LIDAR_Y_OFFSET = 0.7985125; // 31.75"

// Static asset - must be present in "public" folder
const husky_model = "assets/models/HuskyRover.stl";

export default class SimpleLidar {
    private renderer: THREE.WebGLRenderer;
    private camera: THREE.PerspectiveCamera;
    private scene: THREE.Scene;
    private controls: OrbitControls;
    private por: THREE.Mesh;
    private axes: THREE.AxesHelper;
    private groundGrid: THREE.PolarGridHelper;
    private pointcloud!: THREE.Points;

    constructor(container: string) {
        const redHue = 0x990000;
        const whiteHue = 0x999999;

        this.scene = new THREE.Scene();
        this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);

        // Axes
        const radius = 2;
        const sectors = 32;
        const axesLength = 30;
        const porGeometry = new THREE.CircleGeometry(radius, sectors);
        const porMaterial = new THREE.MeshBasicMaterial({ color: redHue });
        this.por = new THREE.Mesh(porGeometry, porMaterial);
        this.axes = new THREE.AxesHelper(axesLength);

        // Remove axes for now, can add back if it helps once real data is run through
        //this.scene.add(this.axes);

        this.renderer = new THREE.WebGLRenderer();
        this.renderer.setSize(window.innerWidth, window.innerHeight);
        document.body.appendChild(this.renderer.domElement);

        this.controls = new OrbitControls(this.camera, this.renderer.domElement);

        const light = new THREE.DirectionalLight(0x404040, 0.5);
        light.position.x = 0.2;
        light.position.y = 4;
        light.position.z = 0;

        this.scene.add(light);

        const ambient_light = new THREE.AmbientLight(0x404040); // soft white light
        this.scene.add(ambient_light);

        const myScene = this.scene;

        const stl_loader = new STLLoader();

        stl_loader.load(
            husky_model.toString(),
            function (geometry) {
                const material = new THREE.MeshPhongMaterial({
                    color: 0xffff00,
                    specular: 0x111111,
                    shininess: 200,
                    side: THREE.DoubleSide,
                });
                const mesh = new THREE.Mesh(geometry, material);
                mesh.geometry.computeVertexNormals();

                mesh.scale.setScalar(0.001);
                myScene.add(mesh);
            },
            (xhr) => {
                console.log((xhr.loaded / xhr.total) * 100 + "% loaded");
            },
            (error) => {
                console.log(error);
            }
        );

        // Main scene objects
        const gridRadius = 10;
        const gridRadials = 4;
        const circles = 10;
        const divisions = 64;
        this.groundGrid = new THREE.PolarGridHelper(gridRadius, gridRadials, circles, divisions, redHue, whiteHue);
        this.scene.add(this.groundGrid);

        const ptGeometry = new THREE.BufferGeometry();

        const ptPositions: number[] = [];

        ptGeometry.setAttribute("position", new THREE.Float32BufferAttribute(ptPositions, 3));

        const ptMaterial = new THREE.PointsMaterial({
            size: 0.01,
            opacity: 1,
            vertexColors: false,
        });

        this.pointcloud = new THREE.Points(ptGeometry, ptMaterial);
        this.scene.add(this.pointcloud);

        const html = document.getElementById(container);
        html?.appendChild(this.renderer.domElement);

        const update = function (this: SimpleLidar): void {
            this.renderer.render(this.scene, this.camera);
            requestAnimationFrame(update);
        }.bind(this);

        this.resetView();

        requestAnimationFrame(update);
    }

    initializeOrbits(controls: OrbitControls): void {
        controls.panSpeed = 0.2;
        controls.screenSpacePanning = true;
        controls.maxPolarAngle = Math.PI / 2;
        controls.enableKeys = false;
        controls.enableDamping = true;
        controls.dampingFactor = 0.2;
        controls.rotateSpeed = 0.2;
    }

    public resetView(): void {
        this.camera.position.x = 0;
        this.camera.position.y = 5;
        this.camera.position.z = -2;
        this.camera.zoom = 1;
        this.camera.updateProjectionMatrix();

        this.controls.target.x = 0;
        this.controls.target.y = 0;
        this.controls.target.z = 0;
        this.controls.update();
    }

    private getPointColor(intensity: number): THREE.Color {
        // higher intensity returns a color closer in hue to the maximum (linear interpolation up to the point of
        // the intensity of the maximum hue, after it will be capped at the maximum hue)

        let pointHue: number;
        if (intensity > MAXIMUM_INTENSITY_HUE[0]) pointHue = MAXIMUM_INTENSITY_HUE[1];
        else
            pointHue =
                ((MAXIMUM_INTENSITY_HUE[1] - MINIMUM_INTENSITY_HUE[1]) /
                    (MAXIMUM_INTENSITY_HUE[0] - MINIMUM_INTENSITY_HUE[0])) *
                    intensity +
                MINIMUM_INTENSITY_HUE[1];

        return new THREE.Color("hsl(" + pointHue + ", 100%, 50%)");
    }

    private generateColorsBasedOnHeight(ptPositions: number[], ptColors: number[]): void {
        // generates color based on height
        for (let i = 0; i < ptPositions.length / 3; i++) {
            const color = BASE_COLOR.clone();

            // positions
            let height = ptPositions[i * 3 + 1];
            if (height > MAX_HEIGHT_COLOR) height = MAX_HEIGHT_COLOR;
            else if (height < MIN_HEIGHT_COLOR) height = MIN_HEIGHT_COLOR;

            color.lerp(TOP_COLOR, (height - MIN_HEIGHT_COLOR) / (MAX_HEIGHT_COLOR - MIN_HEIGHT_COLOR));
            ptColors.push(color.r, color.g, color.b);
        }
    }

    public updatePointCloud(cloud: Uint8Array, isBuffer: boolean = false, areShorts: boolean = false): void {
        const ptPositions: number[] = [];
        const ptColors: number[] = [];

        // attempts to instantiate with the provided buffer
        let buffer: DataView;
        try {
            // buffer comes in the form of the raw bytes
            buffer = new DataView(cloud.buffer);
        } catch (error) {
            console.log("DataView failed to be created from point cloud buffer, defaulting to empty buffer...");
            buffer = new DataView(new ArrayBuffer(0));
        }

        const fspUsesLE = true; // if the FSP graph is sending little-endian values, set to true

        if (!isBuffer || !areShorts) {
            // if not a buffer it is fsp_message::PointCloud (x,y,z [float32], intensity [uint8], padding [3 * uint8])
            // or it is a float buffer which is the same as fsp_message::PointCloud but no padding after intensity

            // both cases are supported by the same code, the only difference is the stride of each point

            let bytesInPoint: number;
            if (!isBuffer) {
                console.log("Rendering point cloud");
                bytesInPoint = 16; // 3 * float32 + uint8 + 3 * uint8
            } else {
                console.log("Rendering float buffer");
                bytesInPoint = 13; // 3 * float32 + uint8
            }

            const numPoints: number = Math.floor(buffer.byteLength / bytesInPoint);
            for (let i = 0; i < numPoints; i++) {
                const idx = i * bytesInPoint;
                ptPositions.push(buffer.getFloat32(idx, fspUsesLE)); // x [float32]
                ptPositions.push(buffer.getFloat32(idx + 4, fspUsesLE)); // y [float32]
                ptPositions.push(buffer.getFloat32(idx + 8, fspUsesLE)); // z [float32]

                const ptColor: THREE.Color = this.getPointColor(buffer.getUint8(idx + 12)); // intensity [uint8]
                ptColors.push(ptColor.r, ptColor.g, ptColor.b);
            }
        } else {
            // all x,y,z values are provided as short values and are equal to the measured point multiplied by 1000
            // to preserve 1 mm of precision (implemented to reduce bandwidth requirements

            console.log("Rendering short buffer");

            const bytesInPoint = 7; // 3 * int16 + uint8
            const numPoints: number = Math.floor(buffer.byteLength / bytesInPoint);

            for (let i = 0; i < numPoints; i++) {
                const idx = i * bytesInPoint;
                ptPositions.push(buffer.getInt16(idx, fspUsesLE) / 1000);
                ptPositions.push(buffer.getInt16(idx + 2, fspUsesLE) / 1000);
                ptPositions.push(buffer.getInt16(idx + 4, fspUsesLE) / 1000);

                const ptColor: THREE.Color = this.getPointColor(buffer.getUint8(idx + 6));
                ptColors.push(ptColor.r, ptColor.g, ptColor.b);
            }
        }

        // can re-enable by commenting out the 2 color lines under each case above (4 total)
        // this.generateColorsBasedOnHeight(ptPositions, ptColors);

        // disposes of the previous geometry and recreates it with the new data
        this.pointcloud.geometry.dispose();

        this.pointcloud.geometry = new THREE.BufferGeometry();
        this.pointcloud.geometry.setAttribute("position", new THREE.Float32BufferAttribute(ptPositions, 3));
        this.pointcloud.geometry.setAttribute("color", new THREE.Float32BufferAttribute(ptColors, 3));

        // apply transformations to rotate the point cloud (upside down LIDAR installation),
        // and translate the point cloud with respect to the offset of the LIDAR

        //const rotation: THREE.Quaternion = new THREE.Quaternion();
        //rotation.setFromAxisAngle(new THREE.Vector3(0, 0, 1), Math.PI) // rotates 180 degrees around positive z

        // this.pointcloud.geometry.applyQuaternion(rotation);
        this.pointcloud.geometry.translate(0, LIDAR_Y_OFFSET, LIDAR_Z_OFFSET);
    }
}
