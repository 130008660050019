type SimpleRCSConfig = {
    VUE_APP_KRATOS_HOSTNAME: string;
    VUE_APP_DART_URL: string;
    VUE_APP_ADMIN_EMAILS: string[];
    VUE_APP_SHOW_LED_HEADLIGHT: boolean;
};

const defaultConfig: SimpleRCSConfig = {
    VUE_APP_KRATOS_HOSTNAME: process.env.VUE_APP_KRATOS_HOSTNAME || "/.ory",
    VUE_APP_DART_URL: process.env.VUE_APP_DART_URL || "ws://localhost:3000",
    VUE_APP_ADMIN_EMAILS: process.env.VUE_APP_ADMIN_EMAILS?.split(",") || ["moon@mcss.com"],
    VUE_APP_SHOW_LED_HEADLIGHT:
        process.env.VUE_APP_SHOW_LED_HEADLIGHT === undefined
            ? false
            : process.env.VUE_APP_SHOW_LED_HEADLIGHT === "true",
};

let config: SimpleRCSConfig;

export default async function getConfig(): Promise<SimpleRCSConfig> {
    if (config !== undefined) return config;

    // If Simple-RCS is served statically, the static-server exposes a /config endoint with configuration parameters.
    // If that request fails, it probably means that this is running from a dev server - in that case let's use the default
    // parameters that we loaded from env variables (they're available now).
    const configResponse = await fetch("/config");
    let receivedConfig: Record<string, string | string[] | boolean> = {};
    if (configResponse.ok) {
        receivedConfig = await configResponse.json();
        if ("VUE_APP_ADMIN_EMAILS" in receivedConfig) {
            receivedConfig["VUE_APP_ADMIN_EMAILS"] = (receivedConfig["VUE_APP_ADMIN_EMAILS"] as string).split(",");
        }
        if ("VUE_APP_SHOW_LED_HEADLIGHT" in receivedConfig) {
            receivedConfig["VUE_APP_SHOW_LED_HEADLIGHT"] = receivedConfig["VUE_APP_SHOW_LED_HEADLIGHT"] === "true";
        }
    }

    config = { ...defaultConfig, ...receivedConfig };

    return config;
}
