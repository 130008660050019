// This file has functionality for subscribing and sending to dart topics of different types  //

import * as Dart from "@mcss/dart/build/web";
import type { Interest } from "@mcss/dart/build/web";
import { DartTopics } from "../dart_constants";

import store from "../store";
import getConfig from "../config";
import driveState from "./driveController";

const DART_STATUS_INTERVAL = 2000;

/** Used for storing the old images urls per topic so that they can be revoked once they are no longer required
 *  If the freeze is enabled, image URLs are saved in the array and are revoked once the freeze is disabled
 */
let freezeURLRevoke = false;
const old_images = new Map<string, string[]>();

let dart: Dart.Dart | undefined;
let dart_ptz: Dart.Dart | undefined;

const user_authenticated_check_interval = 1000;

export interface DartInterfaceOptions {
    delay?: number;
    // The number of acks that can waiting before data is droppped
    // If not set or set to 0 then acks are not required i.e Emit Reliable
    // which results in maximum throughput but can buffer images if the connection is slow
    max_acks_waiting?: number;
}

export function getDARTClient(): Dart.Dart | undefined {
    return dart;
}

export async function initializeDartClient(): Promise<Dart.Dart> {
    const config = await getConfig();
    const dart_url = config.VUE_APP_DART_URL;

    // Wait for the user to sign in
    await (async (): Promise<void> => {
        while (!store.state.authenticated) {
            await new Promise((r) => setTimeout(r, user_authenticated_check_interval));
        }
    })();

    // Wait for the user to sign in
    await (async (): Promise<void> => {
        while (!store.state.authenticated) {
            await new Promise((r) => setTimeout(r, user_authenticated_check_interval));
        }
    })();

    return await Dart.connect(dart_url);
}

async function initializeInternal(): Promise<void> {
    dart = await initializeDartClient();
    dart_ptz = await initializeDartClient();

    setInterval(() => {
        if (dart !== undefined) {
            const status = dart.isConnected();
            console.log(`Dart status: ${status}`);
            store.commit("updateDartStatus", status);
        }
    }, DART_STATUS_INTERVAL);

    setInterval(() => {
        if (dart_ptz !== undefined) {
            const status = dart_ptz.isConnected();
            console.log(`PTZ dart status: ${status}`);
            store.commit("updateDartStatus", status);
        }
    }, DART_STATUS_INTERVAL);
}

const initializeDartPromise = initializeInternal();

export async function initialize(): Promise<void> {
    await initializeDartPromise;
}

export function setFreezeImageURLRevoke(freeze: boolean): void {
    freezeURLRevoke = freeze;
}

/**
 * Helper function for subscribing to image messages on DART.
 *
 * The function subscribes to messages on the topic and converts images into blobs.
 * It invokes the callback with a URL to the blob image.
 * @param dart client.
 * @param topic to subscribe to.
 * @param callback to invoke with image URL.
 */
export function collectImage(
    topic: string,
    callback: (imageURL: string, topic: string) => void,
    dart: Dart.Dart | undefined = getDARTClient()
): Interest | null {
    return collectRaw(
        topic,
        (data: Uint8Array) => {
            // Check if we are logged in.
            if (store.state.authenticated === false) return;

            // Make sure the image buffer is not empty.
            if (data.byteLength <= 0) return;

            // Convert it to a blob.
            const blob = new Blob([data], { type: "image/jpeg" });

            const object_url = URL.createObjectURL(blob);
            // Pass the URL to the image blob.
            callback(object_url, topic);

            let old_urls = old_images.get(topic) as string[];
            if (!old_urls) old_urls = [];
            // Check if freeze is disabled
            if (!freezeURLRevoke) {
                // Delete older images
                old_urls.forEach((url) => {
                    URL.revokeObjectURL(url);
                });
                old_images.set(topic, [object_url]);
            } else {
                // Add new image to be deleted later
                old_urls.unshift(object_url);
                old_images.set(topic, old_urls);
            }
        },
        dart
    );
}

export function collectPtzImage(callback: (imageURL: string, topic: string) => void): Interest | null {
    return collectImage(DartTopics.ptz_stream_topic, callback, dart_ptz);
}

/**
 * Helper function for subscribing to JSON messages on DART.
 *
 * The function subscribes to messages on the topic and decodes to JSON.
 * @param dart client.
 * @param topic to subscribe to.
 * @param callback to invoke with the decoded JSON.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function collectJSON(topic: string, callback: (decoded: any) => void): Dart.Interest | null {
    return collectRaw(topic, (data: Uint8Array) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const message = String.fromCharCode.apply(null, new Uint16Array(data) as any);
        // Convert it to JSON
        const json = JSON.parse(message);
        // Pass the JSON.
        callback(json);
    });
}

/**
 * A convenience function for subscribing to raw buffers on DART.
 *
 * @param topic to subscribe to.
 * @param callback to invoke with the received buffers.
 */
export function collectRaw(
    topic: string,
    callback: (buffer: Uint8Array) => void,
    dart: Dart.Dart | undefined = getDARTClient()
): Dart.Interest | null {
    if (dart === undefined) {
        return null;
    }
    return dart.collect(topic, (data: Uint8Array) => {
        callback(data);
    });
}

export function uncollect(interest: Interest | null): void {
    if (dart === undefined || interest === null) {
        return;
    }
    dart.uncollect(interest);
}

export function uncollectPtz(interest: Interest | null): void {
    if (dart_ptz === undefined || interest === null) {
        return;
    }
    dart_ptz.uncollect(interest);
}

// Topics in here are blocked if the rover is disabled.
const disabledTopics = new Set([
    DartTopics.autonomy_state_topic, // Following a path
    DartTopics.driving_command_topic, // Sending a drive command
]);
// Admins are allowed to send commands even if the rover is disabled.
export const roverDisabled = (): boolean => {
    return store.state.rover.drive_state === driveState.DISABLED;
};

const bypassDisable = (): boolean => store.state.allowedToDrive && store.state.isAdmin;
const blockEmit = (topic: string): boolean => disabledTopics.has(topic) && roverDisabled() && !bypassDisable();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function sendData(topic: string, data: Uint8Array, interface_options?: DartInterfaceOptions): void {
    if (interface_options && interface_options.delay !== undefined && interface_options.delay > 0) {
        const delay = interface_options.delay;
        interface_options.delay = 0;
        setTimeout(() => {
            // Block the emit if the rover has been disabled and the topic is
            // blacklisted (in disabledTopics) - and we're not admin.
            if (!blockEmit(topic)) {
                sendData(topic, data, interface_options);
            }
        }, delay * 1000);
        return;
    }

    if (dart === undefined) {
        return;
    }
    dart.emit(topic, data.buffer, {
        max_acks_waiting: interface_options?.max_acks_waiting,
    });
}
