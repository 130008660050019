//@ts-ignore velocity-animate typing information is not available
import Velocity from "velocity-animate";
import type { Vector2 } from "./types";

class JoystickElement {
    element: HTMLElement;
    rect: { center: Vector2; radius: number };
    current: { vector: Vector2; angle: number; percentage: number };

    // classes can be .js
    constructor(element_: HTMLElement) {
        this.element = element_;
        this.rect = this.calculateRect();
        this.current = this.original;

        // Recalculate the rect on resizing
        window.onresize = (): void => {
            this.rect = this.calculateRect();
        };
    }

    get original(): { vector: { x: number; y: number }; angle: number; percentage: number } {
        return {
            vector: {
                x: 0,
                y: 0,
            },
            angle: 0,
            percentage: 0,
        };
    }
    // : { rect: DOMRect & { center: { x: number; y: number }; radius: number }
    calculateRect(): DOMRect & { center: { x: number; y: number }; radius: number } {
        const rect = this.element.getBoundingClientRect();

        return Object.assign(rect, {
            center: {
                x: rect.left + rect.width / 2,
                y: rect.top + rect.height / 2,
            },
            radius: rect.width / 2, // Improve this
        });
    }
}

class JoystickShaft extends JoystickElement {
    clamp(jsc_type: string, x: number, y: number, boundary: number): { x: number; y: number } {
        if (jsc_type === "full") {
            return this.full_clamp(x, y, boundary);
        } else if (jsc_type === "half") {
            return this.half_clamp(x, y, boundary);
        } else {
            console.error("invalid joystick component type");
            return { x: 0, y: 0 };
        }
    }

    full_clamp(x: number, y: number, boundary: number): { x: number; y: number } {
        const diff = { x, y };

        // Get the distance between the cursor and the center
        const distance = Math.sqrt(Math.pow(diff.x, 2) + Math.pow(diff.y, 2));

        // Get the angle of the line
        const angle = Math.atan2(diff.x, diff.y);
        // Convert into degrees
        this.current.angle = 180 - (angle * 180) / Math.PI;

        // If the cursor is distance from the center is
        // less than the boundary, then return the diff
        //
        // Note: Boundary = radius
        if (distance < boundary) {
            this.current.percentage = (distance / boundary) * 100;
            return (this.current.vector = diff);
        }

        // If it's a longer distance, clamp it
        this.current.percentage = 100;

        return (this.current.vector = {
            x: Math.sin(angle) * boundary,
            y: Math.cos(angle) * boundary,
        });
    }

    half_clamp(x: number, y: number, boundary: number): { x: number; y: number } {
        const diff = { x, y };

        // Get the distance between the cursor and the center
        let distance = Math.sqrt(Math.pow(diff.x, 2) + Math.pow(diff.y, 2));

        // Get the angle of the line
        const angle = Math.atan2(diff.x, diff.y);
        // Check if mouse is below the joystick origin
        const bottom_clamp = Math.abs(angle) < Math.PI / 2 ? true : false;
        // Convert into degrees
        this.current.angle = 180 - (angle * 180) / Math.PI;

        // If the cursor is distance from the center is
        // less than the boundary, then return the diff
        //
        // Note: Boundary = radius
        if (distance < boundary) {
            this.current.percentage = (distance / boundary) * 100;
            // Restrict joystick from moving below origin
            if (bottom_clamp) {
                diff.y = 0;
            }
            return (this.current.vector = diff);
        }

        // If it's a longer distance, clamp it
        this.current.percentage = 100;
        // If mouse is below origin
        // calculate percentage as only horizontal distance
        if (bottom_clamp) {
            distance = Math.abs(diff.x);
            this.current.percentage = Math.min(100, (distance / boundary) * 100);
        }
        const y_val = bottom_clamp ? 0 : Math.cos(angle) * boundary;

        return (this.current.vector = {
            x: Math.sin(angle) * boundary,
            y: y_val,
        });
    }

    move(from: Vector2, to: Vector2, duration: number, callback: () => void): void {
        Velocity(
            this.element,
            {
                translateX: [to.x, from.x],
                translateY: [to.y, from.y],
                translateZ: 0,
            },
            {
                duration: duration,
                queue: false,
                complete() {
                    if (typeof callback === "function") {
                        callback();
                    }
                },
            }
        );
    }
}

export default class Joystick {
    state: string;
    jsc_type: string;
    base: JoystickElement;
    shaft: JoystickShaft;
    boundary: number;
    mouse_start: Vector2;
    overrideactivate: () => boolean;
    ondeactivate: () => void;
    ondrag: (js: Joystick) => void;

    constructor(jsc_type: string, base: HTMLElement, shaft: HTMLElement) {
        this.state = "inactive";
        if (jsc_type !== "full" && jsc_type !== "half") {
            console.error("invalid joystick component type");
        }
        this.jsc_type = jsc_type;
        this.base = new JoystickElement(base);
        this.shaft = new JoystickShaft(shaft);
        this.boundary = this.base.rect.radius * 0.75;
        this.mouse_start = { x: 0, y: 0 };

        this.overrideactivate = (): boolean => false;
        this.ondeactivate = (): void => {};
        this.ondrag = (): void => {};

        this.activate = this.activate.bind(this);
        this.deactivate = this.deactivate.bind(this);
        this.drag = this.drag.bind(this);
    }

    static get ANIMATION_TIME(): number {
        return 100;
    }

    attachEvents(): this {
        this.shaft.element.addEventListener("mousedown", this.activate, false);
        document.addEventListener("mouseup", this.deactivate, false);
        document.addEventListener("mousemove", this.drag, false);

        return this;
    }

    detachEvents(): this {
        this.shaft.element.removeEventListener("mousedown", this.activate, false);
        document.removeEventListener("mouseup", this.deactivate, false);
        document.removeEventListener("mousemove", this.drag, false);

        this.deactivate();

        return this;
    }

    activate(e: MouseEvent): this {
        let ignore = false;
        if (typeof this.overrideactivate === "function") {
            ignore = this.overrideactivate();
        }
        if (!ignore) {
            this.state = "active";
            this.base.element.classList.add("active");
        }

        this.mouse_start = { x: e.clientX, y: e.clientY };
        return this;
    }

    deactivate(): this | undefined {
        if (this.state !== "active") {
            return;
        }

        this.state = "inactive";
        this.base.element.classList.remove("active");

        this.shaft.move(this.shaft.current.vector, this.shaft.original.vector, Joystick.ANIMATION_TIME, () => {
            this.shaft.element.removeAttribute("style");
            this.shaft.current = this.shaft.original;

            if (typeof this.ondeactivate === "function") {
                this.ondeactivate();
            }
        });

        return this;
    }

    drag(e: MouseEvent): this {
        if (this.state !== "active") {
            return this;
        }

        const x_diff = e.clientX - this.mouse_start.x;
        const y_diff = e.clientY - this.mouse_start.y;

        this.shaft.move(
            this.shaft.original.vector,
            this.shaft.clamp(this.jsc_type, x_diff, y_diff, this.boundary),
            0,
            () => {
                if (typeof this.ondrag === "function") {
                    this.ondrag(this);
                }
            }
        );

        return this;
    }
}
