
import { defineComponent } from "vue";
import { DartTopics } from "../../dart_constants";
import { overlayConfig } from "../../rover/overlayConfig";
import { getBoxSize } from "../../img_utils";

export default defineComponent({
    name: "OverlayLabels",
    data() {
        return {
            distanceConfig: overlayConfig.distance,
            gridConfig: overlayConfig.grid,
            distance_topic: DartTopics.distance_topic,
            grid_topic: DartTopics.grid_topic,
        };
    },
    computed: {
        camera() {
            return this.$store.state.overlays.camera;
        },
        distance_labels() {
            const labels = this.$store.state.overlays.distance.labels;
            if (
                labels !== null &&
                this.$store.state.images[this.distance_topic] &&
                this.$store.state.images[this.distance_topic].received &&
                this.$store.state.images[this.distance_topic].show
            ) {
                return labels;
            } else {
                return {}; // Empty object results in loop being skipped
            }
        },
        grid_labels() {
            const labels = this.$store.state.overlays.grid.labels;
            if (
                labels !== null &&
                this.$store.state.images[this.grid_topic] &&
                this.$store.state.images[this.grid_topic].received &&
                this.$store.state.images[this.grid_topic].show
            ) {
                return labels;
            } else {
                return {}; // Empty object results in loop being skipped
            }
        },
        window_size() {
            return this.$store.state.window_size;
        },
        connected() {
            return this.$store.state.rover.connected;
        },
        getBoxSizeX() {
            return getBoxSize(this.$store.state.window_size)[0];
        },
        getBoxSizeY() {
            return getBoxSize(this.$store.state.window_size)[1];
        },
        svgViewbox() {
            const box_size = getBoxSize(this.$store.state.window_size);
            return `0 0 ${box_size[0]} ${box_size[1]}`;
        },
    },
});
